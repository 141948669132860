import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  Input,
  Spinner,
} from "@chakra-ui/react";
import {
  Autocomplete,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const buttonStyles = {
  bg: "#0a1e40",
  size: "md",
  color: "#edbf41",
  borderRadius: "md",
  _hover: {
    bg: "#234b8c",
    color: "#edbf41",
  },
  transition: "ease-in-out",
  transitionDuration: "150ms",
};

const libraries = ["places"];
const cambodiaBounds = {
  north: 14.690422,
  south: 9.932959,
  east: 107.627686,
  west: 102.348465,
};

export default function GoogleMapApi({
  coordinates,
  setCoordinates,
  farmAddress,
}) {
  const { t } = useTranslation();
  const autocompleteRef = useRef(null);
  const mapRef = useRef(null);
  const [address, setAddress] = useState(farmAddress);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOLE_MAP_API,
    libraries,
  });

  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry) {
        const { lat, lng } = place.geometry.location;
        setCoordinates({
          lat: lat(),
          lng: lng(),
        });
        setAddress(place.formatted_address);
      }
    }
  };
  const handleMapClick = useCallback(
    (event) => {
      setCoordinates({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    },
    [setCoordinates],
  );

  const handleRecenter = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({
            lat: latitude,
            lng: longitude,
          });
          if (mapRef.current) {
            mapRef.current.panTo({ lat: latitude, lng: longitude });
          }
        },
        () => {
          alert("Error fetching your location");
        },
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    setAddress(farmAddress);
  }, [farmAddress]);

  if (!isLoaded) {
    return (
      <Container maxW="container.lg" my={20}>
        <Flex align="center" justify="center" h="80vh">
          <Spinner />
        </Flex>
      </Container>
    );
  }

  return (
    <FormControl id="location" my={3}>
      <Autocomplete
        onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
        onPlaceChanged={onPlaceChanged}
        options={{
          componentRestrictions: { country: "KH" },
        }}
      >
        <Input
          required
          type="text"
          placeholder="Search for a location"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          style={{ marginBottom: "10px" }}
        />
      </Autocomplete>
      <Box my={3} height="400px" width="100%">
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100%" }}
          center={
            coordinates.lat
              ? {
                  lat: coordinates.lat,
                  lng: coordinates.lng,
                }
              : { lat: 0, lng: 0 }
          }
          zoom={coordinates.lat ? 15 : 2}
          onClick={handleMapClick}
          options={{
            mapTypeId: "hybrid", // Use hybrid for satellite + labels
            streetViewControl: false, // Disable the Pegman control
            restriction: {
              latLngBounds: cambodiaBounds,
              strictBounds: false,
            },
            mapTypeControlOptions: {
              mapTypeIds: ["hybrid"], // Only show the hybrid option
            },
          }}
        >
          {coordinates.lat && (
            <Marker
              position={{
                lat: coordinates.lat,
                lng: coordinates.lng,
              }}
            />
          )}
        </GoogleMap>
      </Box>
      <Button {...buttonStyles} onClick={handleRecenter}>
        {t("recenter-map")}
      </Button>
    </FormControl>
  );
}
