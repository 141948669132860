// export default GoogleMaps;
import { withTranslation } from "react-i18next";
import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { Button } from "@chakra-ui/react";
import UserService from "../Components/Services/UserService";
import { decodeToken } from "../Utilities/JsonDecode";
import { getToken } from "../Utilities/LocalStorage"; // Removed unused isTokenExpired

const buttonStyles = {
  bg: "#0a1e40",
  size: "md",
  color: "#edbf41",
  borderRadius: "md",
  _hover: {
    bg: "#234b8c",
    color: "#edbf41",
  },
  transition: "ease-in-out",
  transitionDuration: "150ms",
};

class GoogleMaps extends Component {
  map = null;
  maps = null;
  marker = null;
  cityCircle = null;

  componentDidUpdate(prevProps) {
    // Check if center prop has changed
    if (this.props.center !== prevProps.center) {
      this.updateMapCenter();
    }
  }

  recenterMap = async () => {
    const token = getToken();
    const userId = decodeToken(token);
    const user = await UserService.get(`/${userId}`);
    const currentLocation = {
      lat: user.data.coordinates.lat,
      lng: user.data.coordinates.lng,
    };
    this.updateMapCenter(currentLocation);
    if (this.props.onUserLocationChange) {
      this.props.onUserLocationChange(currentLocation);
    }
  };

  updateMapCenter = (location) => {
    const { lat, lng } = location || this.props.center;
    if (this.map && this.maps && lat && lng) {
      this.marker.setPosition(new this.maps.LatLng(lat, lng));
      this.cityCircle.setCenter(new this.maps.LatLng(lat, lng));
      this.map.panTo(new this.maps.LatLng(lat, lng));
    }
  };

  loadMap = (map, maps) => {
    this.map = map;
    this.maps = maps;

    this.cityCircle = new maps.Circle({
      strokeColor: "white",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "white",
      fillOpacity: 0.35,
      map,
      center: this.props.center,
      radius: 500,
      draggable: false,
    });

    this.marker = new maps.Marker({
      position: this.props.center,
      map,
      draggable: false, // Set marker to not be draggable
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div style={{ height: "400px", width: "100%" }}>
        {/* Button to recenter the map */}
        <GoogleMapReact
          bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOLE_MAP_API}` }}
          defaultCenter={this.props.center}
          defaultZoom={10}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.loadMap(map, maps)}
        />
        <Button {...buttonStyles} onClick={this.recenterMap}>
          {t("recenter-map")}
        </Button>
      </div>
    );
  }
}

export default withTranslation()(GoogleMaps);
