export const storeToken = (tokenObject) => {
  localStorage.setItem("farmToken", JSON.stringify(tokenObject));
};

export const getToken = () => {
  const tokenString = localStorage.getItem("farmToken");
  if (!tokenString) return null;

  const tokenObject = JSON.parse(tokenString);
  return tokenObject?.token;
};

export const isTokenExpired = () => {
  const tokenString = localStorage.getItem("farmToken");
  if (tokenString === null || !tokenString) return true;
  const tokenObject = JSON.parse(tokenString);
  const now = new Date();

  // Parse the expiry date string into a Date object
  const expiryDate = new Date(tokenObject.expires);

  // Compare the current time with the expiry time
  const result = now.getTime() > expiryDate.getTime();
  return result;
};

export const clearToken = () => {
  localStorage.removeItem("farmToken");
};

export const clearUserData = (userId) => {
  localStorage.removeItem(`userData_${userId}`);
};

export const getFcmToken = () => {
  const fcmToken = localStorage.getItem("fcmToken");
  return fcmToken;
};
