// ... (previous code)
import React, { useState } from 'react';
import MainLayout from '../Layout/MainLayout';
import './viewDetails.css';
const ViewDetails = () => {
    const initialFormData = [
      {
        BookingConfirmed: false,
        CropType: '',
        EndDate: '',
        EstimatedCost: '',
        FarmAddress: '',
        NumberofHectare: '',
        PaymentConfirmed: false,
        PreferredDate: '',
        ServiceCompleted: false,
        ServiceType: '',
        SoilType: '',
        StartDate: '',
        // Additional fields
        // Add more fields as needed
      },
      // Add more rows as needed
    ];
  
    const [displayedRow, setDisplayedRow] = useState(null);
  
    const handleButtonClick = (rowNumber) => {
      setDisplayedRow(rowNumber);
    };
  
    return (
      <MainLayout>
        <div className="view-details-container">
          {initialFormData.map((rowData, index) => (
            <div key={index} style={{ display: displayedRow === index ? 'block' : 'none' }}>
              {/* Display form content for each row */}
              <form>
                <label>
                  Booking Confirmed:
                  <input type="text" value={rowData.BookingConfirmed.toString()} readOnly />
                </label>
                <label>
                  Crop Type:
                  <input type="text" value={rowData.CropType} readOnly />
                </label>
                <label>
                  End Date:
                  <input type="text" value={rowData.EndDate} readOnly />
                </label>
                <label>
                  Estimated Cost:
                  <input type="text" value={rowData.EstimatedCost} readOnly />
                </label>
                <label>
                  Farm Address:
                  <input type="text" value={rowData.FarmAddress} readOnly />
                </label>
                <label>
                  Number of Hectare:
                  <input type="text" value={rowData.NumberofHectare} readOnly />
                </label>
                <label>
                  Payment Confirmed:
                  <input type="text" value={rowData.PaymentConfirmed.toString()} readOnly />
                </label>
                <label>
                  Preferred Date:
                  <input type="text" value={rowData.PreferredDate} readOnly />
                </label>
                <label>
                  Service Completed:
                  <input type="text" value={rowData.ServiceCompleted.toString()} readOnly />
                </label>
                <label>
                  Service Type:
                  <input type="text" value={rowData.ServiceType} readOnly />
                </label>
                <label>
                  Soil Type:
                  <input type="text" value={rowData.SoilType} readOnly />
                </label>
                <label>
                  Start Date:
                  <input type="text" value={rowData.StartDate} readOnly />
                </label>
                {/* Additional fields */}
                <label>
                  Additional Field 1:
                  <input type="text" value={rowData.AdditionalField1} readOnly />
                </label>
                <label>
                  Additional Field 2:
                  <input type="text" value={rowData.AdditionalField2} readOnly />
                </label>
                {/* Add more fields as needed */}
              </form>
            </div>
          ))}
  
          {/* Buttons to show respective rows */}
          {initialFormData.map((_, index) => (
            <button key={index} onClick={() => handleButtonClick(index)}>
              Show Row {index + 1}
            </button>
          ))}
        </div>
      </MainLayout>
    );
  };
  
  export default ViewDetails;
  

