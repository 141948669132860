import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const ForgetPasswordForm = () => {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const toast = useToast();

  const buttonStyles = {
    colorScheme: "yellow",
    size: "md",
    borderRadius: "md",
    _hover: {
      bg: "yellow.600",
    },
    transition: "ease-in-out",
    transitionDuration: "150ms",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://farmheronewv1-production-4209.up.railway.app/v1/auth/forgot-password",
        {
          phoneNumber,
        }
      );
      if (response.data) {
        toast({
          title: "Password reset link sent.",
          status: "success",
          duration: 5000,
          isClosable: true,
          render: () => (
            <Box color="white" p={3} bg="green.500" borderRadius="lg">
              <p>{t("click-reset-password")}</p>
              <Link
                to={`/${response.data.resetPasswordToken}/newPassword`}
                style={{ color: "white", textDecoration: "underline" }}
              >
                {t("new-password")}
              </Link>
            </Box>
          ),
        });
      }
    } catch (e) {
      toast({
        title: "Error, reseting password.",
        description: "This user doesn't exist",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box my={4} textAlign="left">
      <form onSubmit={handleSubmit}>
        <FormControl>
          <FormLabel>{t("phone-number")}</FormLabel>
          <Input
            type="string"
            placeholder={t("enter-phone-number")}
            onChange={(e) => setPhoneNumber(e.target.value)}
            isRequired
          />
        </FormControl>
        <Button width="full" mt={4} type="submit" {...buttonStyles}>
          {t("send-reset-link")}
        </Button>
      </form>
    </Box>
  );
};

export default ForgetPasswordForm;
