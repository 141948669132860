import UserService from "../UserService";

export async function fetchUserDataWithCache(userId) {
  const localStorageKey = `userData_${userId}`;

  // Try to get the user data from local storage
  const cachedUserData = localStorage.getItem(localStorageKey);
  if (cachedUserData) {
    try {
      // If cached data is found, parse it and return
      return JSON.parse(cachedUserData);
    } catch (error) {
      console.error("Failed to parse user data from local storage:", error);
      // If parsing fails, remove the corrupted data from local storage
      localStorage.removeItem(localStorageKey);
    }
  }

  // Fetch new data if not in local storage or if cached data is corrupted
  try {
    const response = await UserService.get(`/${userId}`);
    if (response && response.data) {
      // Cache the fresh user data in local storage
      localStorage.setItem(localStorageKey, JSON.stringify(response.data));
      return response.data;
    }
  } catch (error) {
    console.error("Failed to fetch user data:", error);
    throw new Error("Could not fetch user data");
  }

  return null; // Return null if both local storage and API request fail
}

export async function updateCachedUserData(userId) {
  const localStorageKey = `userData_${userId}`;

  const cachedUserData = localStorage.getItem(localStorageKey);
  if (cachedUserData) {
    try {
      const response = await UserService.get(`/${userId}`);
      if (response && response.data) {
        // Cache the fresh user data in local storage
        localStorage.setItem(localStorageKey, JSON.stringify(response.data));
        return response.data;
      }
    } catch (error) {
      console.error("Failed to parse user data from local storage:", error);
      localStorage.removeItem(localStorageKey);
    }
  }
  return null;
}
