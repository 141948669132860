import React, { useEffect, useState } from "react";
import { ButtonGroup, Button, Flex } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import MainLayout from "../Layout/MainLayout";
import UserDetails from "../Components/UserDetails";
import BookingDetails from "../Components/BookingDetails";
import { useNavigate, useParams } from "react-router-dom";
import BookingService from "../Components/Services/BookingService";
import { getToken } from "../Utilities/LocalStorage";
import { decodeToken, decodeTokenObject } from "../Utilities/JsonDecode";
import UserService from "../Components/Services/UserService";
import { useTranslation } from "react-i18next";
import AggregateUserDetails from "../Components/AggregateUserDetails";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const ViewBookingDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { bookingId } = useParams();
  const [booking, setBooking] = useState(null);
  const [id, setId] = useState(null);
  const [user, setUser] = useState(null);
  const [serviceType, setServiceType] = useState(null);

  const buttonStyles = {
    colorScheme: "yellow",
    borderRadius: "md",
    _hover: {
      bg: "yellow.600",
    },
    transition: "ease-in-out",
    transitionDuration: "150ms",
  };

  const getBookingData = async () => {
    try {
      const book = await BookingService.get(`/bookingDetail/${bookingId}`);
      setBooking(book.data);
    } catch (e) {
      console.log(e);
      navigate("/viewBooking");
    }
  };

  const getUserData = async () => {
    const token = getToken();
    const userId = decodeToken(token);
    const st = decodeTokenObject(token);
    setId(userId);
    setServiceType(st);

    // Check if user data is cached in local storage
    const cachedUserData = localStorage.getItem(`userData_${userId}`);
    if (cachedUserData) {
      setUser(JSON.parse(cachedUserData));
      return;
    }

    try {
      const userData = await UserService.get(`/${userId}`);
      if (userData) {
        // Cache user data in local storage
        localStorage.setItem(
          `userData_${userId}`,
          JSON.stringify(userData.data)
        );
        setUser(userData.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleAcceptBooking = async (booking) => {
    try {
      console.log(booking.bookerDetails.id);
      await BookingService.patch(`/${booking.id}`, {
        acceptanceID: id,
        bookingConfirmed: true,
      });
      const formData = {
        userId: booking.bookerDetails.id,
        title: "Booking Accepted!",
        body: `${booking.preferredSP.name} accepted your booking!`,
        url: "/viewBooking",
      };
      await notificationMutation.mutateAsync(formData);
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  const notificationMutation = useMutation({
    mutationFn: (formData) => {
      return axios.post(
        process.env.REACT_APP_API_END_POINT +
          "/v1/notification/send-notification",
        formData
      );
    },
  });

  const handleServiceCompleted = async (booking) => {
    try {
      await BookingService.patch(`/${booking.id}/serviceCompleted`, {
        acceptanceID: id,
        serviceCompleted: true,
      });
      const formData = {
        userId: booking.bookerDetails.id,
        title: "Service Completed!",
        body: `${booking.preferredSP.name} has completed the service!`,
        url: "/viewBooking",
      };
      await notificationMutation.mutateAsync(formData);
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  // const handlePaymentToFarmHero = async (booking, serviceType) => {
  //   try {
  //     await BookingService.patch(`/${booking.id}/paymentConfirmed`, {
  //       acceptanceID: booking.acceptanceID,
  //       paymentConfirmed: false,
  //       serviceType: serviceType,
  //     });
  //     window.location.reload();
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  const handlePaymentToSP = async (booking, serviceType) => {
    try {
      await BookingService.patch(`/${booking.id}/paymentConfirmed`, {
        acceptanceID: booking.acceptanceID,
        paymentConfirmed: true,
        serviceType: serviceType,
      });
      const formData = {
        userId: booking.preferredSP.id,
        title: "Payment Completed!",
        body: `${booking.bookerDetails.name} has completed the payment!`,
        url: "/viewBooking",
      };
      await notificationMutation.mutateAsync(formData);
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  const handleCancelBooking = async (booking) => {
    try {
      if (user.serviceType === "serviceProvider") {
        if (booking.acceptanceID !== "" && booking.bookingConfirmed === true) {
          await BookingService.patch(`/cancelBooking/${booking.id}`, {
            customerId: id,
          });
          navigate("/viewBooking");
        } else {
          navigate("/viewBooking");
        }
      } else {
        await BookingService.patch(`/cancelBooking/${booking.id}`, {
          customerId: id,
        });
        navigate("/viewBooking");
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getUserData();
    getBookingData();
  }, []);

  return (
    <MainLayout>
      {booking && user && (
        <>
          <Button
            {...buttonStyles}
            size="lg"
            w={{ md: "20%", base: "100%" }}
            ml={{ md: 5, sm: 0 }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon boxSize={8} />
          </Button>
          <Flex
            direction={{ md: "row", sm: "column", base: "column" }}
            m={{ md: 0, sm: 0 }}
          >
            <BookingDetails booking={booking} />
            {booking.state === "aggregated" ? (
              <AggregateUserDetails
                booking={booking}
                serviceType={serviceType}
              />
            ) : (
              <UserDetails booking={booking} serviceType={serviceType} />
            )}
          </Flex>
          <Flex justify="flex-end" mr={6}>
            <ButtonGroup spacing={6}>
              {user.serviceType === "serviceProvider" &&
                booking.bookingConfirmed !== true && (
                  <Button
                    colorScheme="green"
                    size="lg"
                    onClick={() => {
                      handleAcceptBooking(booking);
                    }}
                  >
                    {t("accept")}
                  </Button>
                )}
              {/* TODO: Hide new payment flow for now */}
              {/* show service completed button for SP */}
              {user.serviceType === "serviceProvider" &&
                booking.bookingConfirmed === true &&
                !booking.serviceCompleted &&
                !booking.paymentConfirmed && (
                  <Button
                    colorScheme="green"
                    size={"lg"}
                    px={"40px"}
                    onClick={() => {
                      handleServiceCompleted(booking);
                    }}
                  >
                    {t("service_completed")}
                  </Button>
                )}

              {/* {user.serviceType === "bookingAgent" &&
                booking.bookingConfirmed === true &&
                booking.serviceCompleted &&
                booking.customerPaymentStatus === "" && (
                  <Button
                    colorScheme="green"
                    size="lg"
                    onClick={() => {
                      handlePaymentToFarmHero(booking, user.serviceType);
                    }}
                  >
                    {t("paid-to-fh")}
                  </Button>
                )} */}

              {/* {user.serviceType === "farmer" &&
                booking.bookingConfirmed === true &&
                booking.serviceCompleted &&
                booking.customerPaymentStatus === "" && (
                  <Button
                    colorScheme="green"
                    size="lg"
                    onClick={() => {
                      handlePaymentToFarmHero(booking, user.serviceType);
                    }}
                  >
                    {t("paid-to-fh")}
                  </Button>
                )} */}

              {(user.serviceType === "bookingAgent" ||
                user.serviceType === "farmer") &&
                booking.bookingStatus === "service_completed" && (
                  <Button
                    colorScheme="green"
                    size="lg"
                    onClick={() => {
                      handlePaymentToSP(booking, user.serviceType);
                    }}
                  >
                    {t("payment-done")}
                  </Button>
                )}

              {!booking.serviceCompleted && (
                <Button
                  colorScheme="red"
                  size="lg"
                  onClick={() => handleCancelBooking(booking)}
                >
                  {t("reject")}
                </Button>
              )}
            </ButtonGroup>
          </Flex>
        </>
      )}
    </MainLayout>
  );
};

export default ViewBookingDetails;
