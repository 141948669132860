import { jwtDecode } from "jwt-decode";

export const decodeToken = (accessToken) => {
  const jwt = jwtDecode(accessToken);
  return jwt.sub;
};

export const decodeTokenObject = (accessToken) => {
  const jwt = jwtDecode(accessToken);
  return jwt.serviceType;
};
