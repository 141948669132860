import React from "react";
import Navigationbar from "../Components/Navigationbar";
import styles from "./MainLayout.css"; // Import the CSS module

function MainLayout({ children }) {
  return (
    <div>
      <Navigationbar></Navigationbar>
      <div className={styles.bodylayoutcontainer}>{children}</div>
      {/* <Footer></Footer> */}
    </div>
  );
}

export default MainLayout;
