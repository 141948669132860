import { useState, useEffect } from "react";
import MainLayout from "../Layout/MainLayout";
import "./CreateBookingPage.css";
import { useNavigate } from "react-router-dom";

import UserService from "../Components/Services/UserService";
import { useTranslation } from "react-i18next";
import BookingService from "../Components/Services/BookingService";
import { decodeToken } from "../Utilities/JsonDecode";
import { getToken, isTokenExpired } from "../Utilities/LocalStorage";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Button,
  Container,
  Box,
  Image,
  Flex,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import GetAddress from "../CustomFunctions/geoCode";
import GoogleMaps from "./GoogleMap";
import BadService from "../Components/Services/BadService";
import GoogleMapApi from "../Components/GoogleMapApi";

const FarmerCreateBooking = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const [serviceType, setServiceType] = useState("");
  const [eligibleAgent, setEligibleAgent] = useState([]);
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [proxySp, setProxySp] = useState([]);
  const [costPerHectare, setCostPerHectare] = useState(0);
  const [nextPaymentDays, setNextPaymentDays] = useState("0");
  const [disableButton, setDisableButton] = useState(false);
  const [bookservice, setbookservice] = useState({
    farmAddress: "",
    coordinates: {},
    preferredDate: Date.now() + 1,
    cropType: "",
    serviceType: "",
    soilType: "",
    numberOfHectare: 0,
    setPricing: 0,
    estimatedCost: 0,
    bookingConfirmed: false,
    paymentConfirmed: false,
    serviceCompleted: false,
    acceptanceID: "",
    preferredSP: {
      id: "",
      name: "",
      contact: "",
    },
    totalHour: 0,
    bookerDetails: {
      id: "",
      name: "",
      phoneNumber: "",
    },
    customerDetails: {
      id: "",
      name: "",
      phoneNumber: "",
      gender: "",
      dob: "",
    },
    discountDetails: {
      code: "",
      discountValueGiven: null,
    },
  });
  const formattedCost = `KHR ${bookservice.estimatedCost.toLocaleString(
    "en-US"
  )}`;

  const setCoordinates = async (coords) => {
    const address = await GetAddress(coords.lat, coords.lng);
    setbookservice((prevService) => ({
      ...prevService,
      farmAddress: address,
      coordinates: coords,
    }));
  };

  const buttonStyles = {
    colorScheme: "yellow",
    size: "md",
    borderRadius: "md",
    _hover: {
      bg: "yellow.600",
    },
    transition: "ease-in-out",
    transitionDuration: "150ms",
  };
  const [userLocation, setUserLocation] = useState({
    lat: 11.5642373,
    lng: 104.9111526,
  });
  const costRateConfig = {
    RICE_NO_TILL_PLANTER: {
      min: 140000,
      max: 160000,
    },
    MAIZE_NO_TILL_PLANTER: {
      min: 140000,
      max: 160000,
    },
    LAND_LEVELER: {
      min: 140000,
      max: 150000,
    },
    LASER_LAND_LEVELER: {
      min: 170000,
      max: 180000,
    },
    SOIL_RIDGING_EQUIPMENT: {
      min: 180000,
      max: 220000,
    },
    CASSAVA_ROOT_HARVESTER: {
      min: 180000,
      max: 220000,
    },
    DISC_PLOUGH_6: {
      min: 120000,
      max: 160000,
    },
    DISC_PLOUGH_3: {
      min: 320000,
      max: 340000,
    },
    ROTAVATOR_DRIED: {
      min: 90000,
      max: 140000,
    },
    ROTAVATOR_WET: {
      min: 150000,
      max: 190000,
    },
    COMBINE_HARVESTER: {
      min: 400000,
      max: 480000,
    },
  };
  const setBooking = async (e) => {
    let { name, value } = e.target;
    if (name === "numberOfHectare") {
      value = parseFloat(value);
      setbookservice((bookservice) => ({ ...bookservice, [name]: value }));
    } else if (name === "totalHour") {
      value = parseFloat(value);
      setbookservice((bookservice) => ({
        ...bookservice,
        [name]: value,
      }));
    } else if (name === "serviceType") {
      if (value === "") {
        setbookservice({
          ...bookservice,
          [name]: value,
        });
      } else {
        if (["LAND_LEVELER", "LASER_LAND_LEVELER"].includes(value)) {
          if (bookservice.totalHour !== 0) {
            setbookservice({
              ...bookservice,
              [name]: value,
              setPricing: costRateConfig[value].min,
            });
            // on service type changes, will query for proxy SP
            setLoading(true);
            const users = await UserService.get("/preferredSp", {
              params: {
                equipmentType: value,
                preferredDate: bookservice.preferredDate,
                serviceMeasure: bookservice.totalHour,
              },
            });
            if (users) {
              setProxySp(users.data);
              setLoading(false);
            }
            // setLoading(false);
          } else {
            setbookservice({
              ...bookservice,
              [name]: value,
              setPricing: costRateConfig[value].min,
            });
          }
        } else {
          if (bookservice.numberOfHectare !== 0) {
            setbookservice({
              ...bookservice,
              [name]: value,
              setPricing: costRateConfig[value].min,
            });
            // on service type changes, will query for proxy SP
            setLoading(true);
            const users = await UserService.get("/preferredSp", {
              params: {
                equipmentType: value,
                preferredDate: bookservice.preferredDate,
                serviceMeasure: bookservice.numberOfHectare,
              },
            });
            if (users) {
              setProxySp(users.data);
              setLoading(false);
            }
            // setLoading(false);
          } else {
            setbookservice({
              ...bookservice,
              [name]: value,
              setPricing: costRateConfig[value].min,
            });
          }
        }
      }

      if (value !== "") {
        setCostPerHectare(costRateConfig[value].min);
      } else {
        setCostPerHectare(0);
      }
    } else if (name === "preferredDate") {
      let preferredPayment = addDaysToDate(value, nextPaymentDays);
      setbookservice({
        ...bookservice,
        [name]: value,
        ["preferredPaymentDate"]: preferredPayment,
      });
    } else if (name === "nextPaymentDays") {
      setNextPaymentDays(value);
      let preferredPayment = addDaysToDate(bookservice.preferredDate, value);
      setbookservice({
        ...bookservice,
        ["preferredPaymentDate"]: preferredPayment,
      });
    } else if (name === "preferredSP") {
      const user = await UserService.get(`/${value}`);
      setbookservice({
        ...bookservice,
        [name]: {
          id: user.data ? value : "",
          name: user.data ? user.data.name : "",
          contact: user.data ? user.data.phoneNumber : "",
        },
      });
    } else {
      setbookservice({ ...bookservice, [name]: value });
    }
  };

  const calculateEstimatedCost = async () => {
    const { serviceType, numberOfHectare, totalHour } = bookservice;

    if (serviceType) {
      if (serviceType === "LAND_LEVELER" && totalHour !== 0) {
        var estimatedCost = 100000 * totalHour;
        setbookservice({ ...bookservice, estimatedCost: estimatedCost });
      }
      if (serviceType === "LASER_LAND_LEVELER" && totalHour !== 0) {
        var estimatedCost = 170000 * totalHour;
        setbookservice({ ...bookservice, estimatedCost: estimatedCost });
      } else if (
        !["LAND_LEVELER", "LASER_LAND_LEVELER"].includes(serviceType)
      ) {
        var estimatedCost = costPerHectare * numberOfHectare;
        setbookservice({
          ...bookservice,
          estimatedCost: estimatedCost,
          totalHour: 0,
        });
      }
    }
  };
  const handleMarkerPositionChange = async (newPosition) => {
    setUserLocation(newPosition);
    const address = await GetAddress(newPosition.lat, newPosition.lng);

    setbookservice((bookservice) => ({
      ...bookservice,
      ["farmAddress"]: address,
      ["coordinates"]: { lat: newPosition.lat, lng: newPosition.lng },
    }));
  };

  const setLocation = async (location) => {
    setUserLocation({ lat: location.lat, lng: location.lng });
    if (bookservice.serviceType !== "") {
      setLoading(true);
      // on coordinates changes and serviceType not empty, will query for proxy SP
      const users = await UserService.get("/preferredSp", {
        params: {
          equipmentType: bookservice.serviceType,
          preferredDate: bookservice.preferredDate,
          serviceMeasure: ["LAND_LEVELER", "LASER_LAND_LEVELER"].includes(
            bookservice.serviceType
          )
            ? bookservice.totalHour
            : bookservice.numberOfHectare,
        },
      });
      if (users) {
        setProxySp(users);
      }
      setLoading(false);
      const address = await GetAddress(location.lat, location.lng);
      setbookservice((bookservice) => ({
        ...bookservice,
        ["farmAddress"]: address,
        ["coordinates"]: { lat: location.lat, lng: location.lng },
      }));
    } else {
      const address = await GetAddress(location.lat, location.lng);
      setbookservice((bookservice) => ({
        ...bookservice,
        ["farmAddress"]: address,
        ["coordinates"]: { lat: location.lat, lng: location.lng },
      }));
    }
  };

  const handlePriceChange = async (value) => {
    setCostPerHectare(value);
    setbookservice({ ...bookservice, setPricing: value });
  };

  const getdata = async (e) => {
    e.preventDefault();
    // Basic form validation
    if (
      !bookservice.farmAddress ||
      !bookservice.preferredDate ||
      !bookservice.cropType ||
      !bookservice.serviceType ||
      !bookservice.soilType ||
      !bookservice.numberOfHectare ||
      !bookservice.estimatedCost ||
      (bookservice.serviceType === "LAND_LEVELER" && !bookservice.totalHour) ||
      (bookservice.serviceType === "LASER_LAND_LEVELER" &&
        !bookservice.totalHour)
    ) {
      alert(`${t("missing-info")}`);
      return;
    }
    try {
      setDisableButton(true);
      await BookingService.post("", bookservice);

      alert(`${t("booking-created")}`);
      navigate("/viewBooking");
    } catch (error) {
      console.error("Error sending request:", error);
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setDisableButton(false);
    }
  };

  function addDaysToDate(dateStr, daysStr) {
    // Step 1: Create a date object from the input string
    let date = new Date(dateStr);

    // Step 2: Convert the days string to an integer
    let days = parseInt(daysStr, 10);

    // Step 3: Add the days to the date
    date.setDate(date.getDate() + days);

    // Step 4: Format the date back to YYYY-MM-DD
    let newDate = date.toISOString().split("T")[0];

    return newDate;
  }

  const fetchUserData = async () => {
    const token = getToken();
    const userId = decodeToken(token);
    const user = await UserService.get(`/${userId}`);
    setUserId(userId);
    if (user) {
      const userServiceType = user.data.serviceType;

      setServiceType(userServiceType);
      setUserLocation({
        lat: user.data.coordinates.lat,
        lng: user.data.coordinates.lng,
      });
      const address = await GetAddress(
        user.data.coordinates.lat,
        user.data.coordinates.lng
      );

      setbookservice((bookservice) => ({
        ...bookservice,
        ["farmAddress"]: address,
        ["coordinates"]: {
          lat: user.data.coordinates.lat,
          lng: user.data.coordinates.lng,
        },
        bookerDetails: {
          ...bookservice.bookerDetails,
          id: userId,
          phoneNumber: user.data.phoneNumber,
          name: user.data.name,
        },
        customerDetails: {
          ...bookservice.customerDetails,
          id: userId,
          name: user.data.name,
          phoneNumber: user.data.phoneNumber,
          gender: user.data.gender,
          dob: user.data.dob,
        },
      }));

      if (userServiceType === "serviceProvider") {
        navigate("/viewBooking");
      } else if (userServiceType === "farmer") {
        navigate("/farmerBooking");
      } else {
        navigate("/createBooking");
      }
      fetchData();
    } else {
      console.log("Unauthorized");
      setLoading(false);
    }
  };

  const checkToken = async () => {
    const expired = isTokenExpired();
    if (expired === true) {
      navigate("/login");
    } else {
      fetchUserData();
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  useEffect(() => {
    const fetchProxySp = async () => {
      // Check if serviceType and coordinates are not empty or undefined
      if (
        bookservice.serviceType !== "" &&
        bookservice.preferredDate !== "" &&
        (["LAND_LEVELER", "LASER_LAND_LEVELER"].includes(
          bookservice.serviceType
        )
          ? bookservice.totalHour !== 0
          : bookservice.numberOfHectare !== 0)
      ) {
        try {
          setLoading(true);
          const response = await UserService.get("/preferredSp", {
            params: {
              equipmentType: bookservice.serviceType,
              preferredDate: bookservice.preferredDate,
              serviceMeasure: ["LAND_LEVELER", "LASER_LAND_LEVELER"].includes(
                bookservice.serviceType
              )
                ? bookservice.totalHour
                : bookservice.numberOfHectare,
            },
          });
          // Assuming the response data is the array of proxy service providers
          if (response && response.data) {
            setProxySp(response.data);
            setLoading(false);
          }
          // setLoading(false);
        } catch (error) {
          console.error("Failed to fetch proxy service providers:", error);
          // Handle errors or set state accordingly
        }
      }
    };

    fetchProxySp();
  }, [
    bookservice.serviceType,
    bookservice.preferredDate,
    ["LAND_LEVELER", "LASER_LAND_LEVELER"].includes(bookservice.serviceType)
      ? bookservice.totalHour
      : bookservice.numberOfHectare,
  ]);

  const fetchData = async () => {
    // Replace this with your actual logic to fetch data based on service type
    console.log("Fetching data based on service type:", serviceType);
  };

  return (
    <MainLayout>
      {loading && (
        <Flex
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          width="100vw"
          height="100vh"
          align="center"
          justify="center"
          backgroundColor="rgba(255, 255, 255, 0.5)" // Semi-transparent white
          zIndex="modal" // Ensures it's above everything else
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Flex>
      )}
      <Container>
        <h2>{t("make-booking")}</h2>
        {/* <h2>Create your bookings here</h2> */}
        <div className="CreateBookingPage">
          <div className="BookingContainer">
            <form onSubmit={getdata}>
              <label htmlFor="customerPhoneNumber">{t("phone-number")}</label>
              <input
                type="text"
                id="customerPhoneNumber"
                name="customerPhoneNumber"
                value={bookservice.bookerDetails.phoneNumber}
                autoComplete="off"
                placeholder={t("phone-number")}
                required
                disabled
                onChange={setBooking}
              />
              <br></br>
              <label htmlFor="name">{t("full-name")}</label>
              <input
                type="text"
                id="name"
                name="name"
                value={bookservice.bookerDetails.name}
                autoComplete="off"
                placeholder={t("full-name")}
                required
                disabled
                onChange={setBooking}
              />
              <br></br>
              <label htmlFor="farmAddress">{t("address")}</label>
              <GoogleMapApi
                coordinates={bookservice.coordinates}
                setCoordinates={setCoordinates}
                farmAddress={bookservice.farmAddress}
              />
              <br></br>
              <br></br>
              <label htmlFor="preferredDate">{t("request-date")}</label>
              <input
                type="date"
                id="preferredDate"
                name="preferredDate"
                value={bookservice.preferredDate}
                required
                min={(() => {
                  const today = new Date();
                  return today.toISOString().split("T")[0];
                })()}
                max={(() => {
                  const today = new Date();
                  const futureDate = new Date(today);
                  futureDate.setDate(futureDate.getDate() + 5);
                  return futureDate.toISOString().split("T")[0];
                })()}
                onChange={setBooking}
                lang="km-KH"
              />
              <div className="dropdown">
                <label htmlFor="cropType">{t("crop-type")}</label>
                <select
                  id="cropType"
                  name="cropType"
                  onChange={setBooking}
                  value={bookservice.cropType}
                  // required onChange={setBooking}
                >
                  <option value=""></option>
                  <option value="rice">{t("rice")}</option>
                  <option value="maize">{t("maize")}</option>
                  <option value="potato">{t("potato")}</option>
                  <option value="bean">{t("bean")}</option>
                  <option value="coverCrop">{t("coverCrop")}</option>
                </select>
              </div>
              <div className="dropdown">
                <label htmlFor="serviceType">{t("service-type")}</label>
                <select
                  id="serviceType"
                  name="serviceType"
                  required
                  onChange={setBooking}
                  onBlur={calculateEstimatedCost}
                >
                  <option value=""></option>
                  <option value="RICE_NO_TILL_PLANTER">
                    {t("RICE_NO_TILL_PLANTER")}
                  </option>
                  <option value="MAIZE_NO_TILL_PLANTER">
                    {t("MAIZE_NO_TILL_PLANTER")}
                  </option>
                  <option value="LAND_LEVELER">{t("LAND_LEVELER")}</option>
                  <option value="LASER_LAND_LEVELER">
                    {t("LASER_LAND_LEVELER")}
                  </option>
                  <option value="SOIL_RIDGING_EQUIPMENT">
                    {t("SOIL_RIDGING_EQUIPMENT")}
                  </option>
                  <option value="CASSAVA_ROOT_HARVESTER">
                    {t("CASSAVA_ROOT_HARVESTER")}
                  </option>
                  <option value="DISC_PLOUGH_3">{t("DISC_PLOUGH_3")}</option>
                  <option value="DISC_PLOUGH_6">{t("DISC_PLOUGH_6")}</option>
                  <option value="ROTAVATOR_DRIED">
                    {t("ROTAVATOR_DRIED")}
                  </option>
                  <option value="ROTAVATOR_WET">{t("ROTAVATOR_WET")}</option>
                  <option value="COMBINE_HARVESTER">
                    {t("COMBINE_HARVESTER")}
                  </option>
                </select>
              </div>
              {!["LAND_LEVELER", "LASER_LAND_LEVELER"].includes(
                bookservice.serviceType
              ) && (
                <div className="m-3">
                  <p>{costPerHectare} KHR</p>
                  <Slider
                    value={costPerHectare}
                    min={
                      bookservice.serviceType !== ""
                        ? costRateConfig[bookservice.serviceType].min
                        : 0
                    }
                    max={
                      bookservice.serviceType !== ""
                        ? costRateConfig[bookservice.serviceType].max
                        : 0
                    }
                    step={10000}
                    onChange={async (value) => {
                      await handlePriceChange(value);
                    }}
                    onChangeEnd={calculateEstimatedCost}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb boxSize={6} />
                  </Slider>
                </div>
              )}
              {["LAND_LEVELER", "LASER_LAND_LEVELER"].includes(
                bookservice.serviceType
              ) && (
                <>
                  <label>{t("land-leveler-pricing")}</label>
                  <label htmlFor="totalHour">{t("hour-usage")}</label>
                  <input
                    type="number"
                    step=".5"
                    id="totalHour"
                    name="totalHour"
                    value={bookservice.totalHour}
                    autoComplete="off"
                    placeholder={t("hour-usage")}
                    min={1.0}
                    max={50.0}
                    required
                    onChange={setBooking}
                    onBlur={calculateEstimatedCost}
                  />
                </>
              )}
              <div className="dropdown">
                <label htmlFor="soilType">{t("land-type")}</label>
                <select
                  id="soilType"
                  name="soilType"
                  value={bookservice.soilType}
                  required
                  onChange={setBooking}
                >
                  <option value=""></option>
                  <option value="bsc">{t("bsc")}</option>
                  <option value="nfs">{t("nfs")}</option>
                  <option value="sand">{t("sand")}</option>
                  <option value="gravel">{t("gravel")}</option>
                </select>
              </div>
              <br></br>
              <label htmlFor="numberOfHectare">{t("number-hectare")}</label>
              <input
                type="number"
                step=".1"
                id="numberOfHectare"
                name="numberOfHectare"
                value={bookservice.numberOfHectare}
                placeholder={t("number-hectare")}
                autoComplete="off"
                required
                min={0.1}
                onChange={setBooking}
                onBlur={calculateEstimatedCost}
              />
              <br></br>
              <div className="dropdown">
                <label htmlFor="preferredSP">{t("select-sp")}</label>
                <select
                  id="preferredSP"
                  name="preferredSP"
                  value={bookservice.preferredSP.id}
                  onChange={setBooking}
                >
                  <option value=""></option>
                  {proxySp.length > 0 &&
                    proxySp.map((sp) => (
                      <option key={sp.id} value={sp.id}>
                        {sp.name}
                      </option>
                    ))}
                </select>
              </div>
              <br></br>
              <label htmlFor="estimatedCost">{t("estimate-cost")} (KHR)</label>
              {/* <label htmlFor="estimatedCost">Estimated Cost</label> */}
              <input
                type="text"
                id="estimatedCost"
                name="estimatedCost"
                value={formattedCost}
                placeholder="តម្លៃប៉ាន់ប្រមាណ"
                // placeholder="Estimated Cost"
                autoComplete="off"
                disabled
              />
              <br></br>
              {/* TODO: remove payment date option for now */}
              {/* <div className="dropdown">
                <label htmlFor="nextPaymentDays">
                  {t("payment-date-to-farm-hero")}
                </label>
                <select
                  id="nextPaymentDays"
                  name="nextPaymentDays"
                  value={nextPaymentDays}
                  required
                  onChange={setBooking}
                >
                  <option value="0">
                    {t("today")} (
                    {addDaysToDate(bookservice.preferredDate, "0")})
                  </option>
                  <option value="7">
                    {t("one-week")} (
                    {addDaysToDate(bookservice.preferredDate, "7")})
                  </option>
                  <option value="14">
                    {t("two-weeks")} (
                    {addDaysToDate(bookservice.preferredDate, "14")})
                  </option>
                </select>
              </div> */}
              <br></br>
              <Button
                {...buttonStyles}
                type="submit"
                isDisabled={disableButton}
                onClick={() => setbookservice({ ...bookservice })}
              >
                {t("request")}
              </Button>
            </form>
          </div>
        </div>
      </Container>
      <Box position="fixed" bottom="5" right="5" zIndex="tooltip">
        <a href="https://t.me/+855975296777">
          <Image
            src="/telegram.png"
            alt="Descriptive Alt Text"
            w={{ md: "70px", base: "50px" }}
            h={{ md: "70px", base: "50px" }}
          />
        </a>
      </Box>
    </MainLayout>
  );
};

export default FarmerCreateBooking;
