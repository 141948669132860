import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import MainLayout from "../Layout/MainLayout";
import { useEffect, useRef, useState } from "react";
import GoogleMapApi from "../Components/GoogleMapApi";
import { useTranslation } from "react-i18next";
import GetAddress from "../CustomFunctions/geoCode";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router";
import { isTokenExpired } from "../Utilities/LocalStorage";

const RegisterNewCustomer = ({ isOpen, onOpen, onClose }) => {
  const [loading, setLoading] = useState(false);
  const initialRef = useRef(null);
  const [cusName, setCusName] = useState("");
  const [cusPhoneNumber, setCusPhoneNumber] = useState("");
  const [cusDob, setCusDob] = useState("");
  const [cusGender, setCusGender] = useState("");
  const [cusFarmAddress, setCusFarmAddress] = useState("");
  const [cusFarmCoordinates, setCusFarmCoordinates] = useState({
    lat: 11.5642373,
    lng: 104.9111526,
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const toast = useToast();

  const checkToken = async () => {
    const expired = isTokenExpired();
    if (expired) {
      navigate("/login");
    }
  };

  const createUserMutation = useMutation({
    mutationFn: (formData) => {
      return axios.post(
        process.env.REACT_APP_API_END_POINT + "/v1/auth/register",
        formData,
      );
    },
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (data) => {
      // Handle successful mutation
      setLoading(false);
      toast({
        title: `${t("successfully-added-farmer")}`,
        description: `${t("successfully-added-farmer-desc")}`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate("/createBooking");
    },
    onError: (error) => {
      // Handle error
      setLoading(false);
      // TODO: comment failed toast for now
      // toast({
      //   title: `${t("failed-added-farmer")}`,
      //   description: `${t("failed-added-farmer-desc")}`,
      //   status: "failed",
      //   duration: 9000,
      //   isClosable: true,
      // });

      alert(error.response.data.message);
      console.log("Error adding client:", error);
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = {
      name: cusName,
      phoneNumber: cusPhoneNumber,
      password: "farmer12345",
      serviceType: "farmer",
      gender: cusGender,
      dob: cusDob,
      farmAddress: cusFarmAddress,
      coordinates: cusFarmCoordinates,
      serviceProviderOptions: [],
    };

    createUserMutation.mutate(formData);
  };

  const setCoordinates = async (coords) => {
    const address = await GetAddress(coords.lat, coords.lng);
    setCusFarmAddress(address);
    setCusFarmCoordinates(coords);
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({
            lat: latitude,
            lng: longitude,
          });
          if (mapRef.current) {
            mapRef.current.panTo({ lat: latitude, lng: longitude });
          }
        },
        () => {
          alert("Error fetching your location");
        },
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const calculateMinDOB = () => {
    const today = new Date();
    const minDate = new Date(today.setFullYear(today.getFullYear() - 18));
    return minDate.toISOString().split("T")[0];
  };

  useEffect(() => {
    checkToken();
    getCurrentLocation();
  }, []);

  return (
    <MainLayout>
      {loading && (
        <Flex
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          width="100vw"
          height="100vh"
          align="center"
          justify="center"
          backgroundColor="rgba(255, 255, 255, 0.5)" // Semi-transparent white
          zIndex="modal" // Ensures it's above everything else
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Flex>
      )}
      <Container>
        <Heading mt={7}>{t("add-new-customer")}</Heading>
        <form onSubmit={handleSubmit}>
          <FormLabel fontSize={"x-large"}>{t("client-information")}</FormLabel>
          <FormControl my={3} isRequired>
            <Input
              name="cusName"
              value={cusName}
              ref={initialRef}
              placeholder={t("full-name")}
              onChange={(e) => setCusName(e.target.value)}
            />
          </FormControl>

          <FormControl my={3} isRequired>
            <InputGroup>
              <InputLeftAddon>855</InputLeftAddon>
              <Input
                name="cusPhoneNumber"
                value={cusPhoneNumber}
                type="tel"
                placeholder={t("phone-number")}
                pattern="[0-9]*"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => setCusPhoneNumber(e.target.value)}
              />
            </InputGroup>
          </FormControl>

          <FormControl isRequired>
            <Input
              type="date"
              id="customerDob"
              name="customerDob"
              value={cusDob}
              required
              max={calculateMinDOB()}
              onChange={(e) => setCusDob(e.target.value)}
              lang="km-KH"
            />
          </FormControl>

          <FormControl isRequired>
            <select
              name="cusGender"
              value={cusGender}
              size="md"
              placeholder="Gender"
              id="cusGender"
              required
              onChange={(e) => setCusGender(e.target.value)}
            >
              <option value={""}>{t("select-gender")}</option>
              <option value={"male"}>{t("male")}</option>
              <option value={"female"}>{t("female")}</option>
              <option value={"other"}>{t("noShow")}</option>
              <option value={"other"}>{t("other")}</option>
            </select>
          </FormControl>

          <FormLabel fontSize={"x-large"}>{t("address")}</FormLabel>
          <GoogleMapApi
            coordinates={cusFarmCoordinates}
            setCoordinates={setCoordinates}
            farmAddress={cusFarmAddress}
          />

          <Button type="submit" colorScheme="yellow">
            {t("submit")}
          </Button>
        </form>
      </Container>
    </MainLayout>
  );
};

export default RegisterNewCustomer;
