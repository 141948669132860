import {
  onMessageListener,
  requestForToken,
} from "../../firebaseNotification/firebase";

export const initializeNotification = (userId, userData) => {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
      // Request permission to show notifications and get the token
      requestForToken(userId, userData);
    } else {
      console.log("Unable to get permission to notify.");
    }
  });

  // Handle foreground messages
  onMessageListener()
    .then((payload) => {
      console.log("Message received. ", payload);
      // Show a notification or alert based on payload
      if (Notification.permission === "granted") {
        const notificationTitle = payload.notification.title;
        const notificationOptions = {
          body: payload.notification.body,
        };
        new Notification(notificationTitle, notificationOptions);
      }
    })
    .catch((err) => console.log("Failed to receive foreground message ", err));
};
