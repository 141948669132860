import React from "react";
import "./index.css";
import App from "./App";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import { i18n } from "./i18n/config";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const queryClient = new QueryClient();

const theme = extendTheme({
  colors: {
    brand: {
      blue: "#0a1e40",
      // ...
      yellow: "#edbf41",
    },
  },
});

const root = createRoot(document.getElementById("root"));

root.render(
  <I18nextProvider i18n={i18n}>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ChakraProvider>
    </BrowserRouter>
  </I18nextProvider>,
);

reportWebVitals();
