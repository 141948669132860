import { Link as RouterLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import GetAddress from "../CustomFunctions/geoCode";
import GoogleMaps from "./GoogleMap";
import {
  Flex,
  Center,
  Box,
  Image,
  HStack,
  VStack,
  Radio,
  RadioGroup,
  Button,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Select,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import DropdownSelectWithTags from "../Components/DropdownSelectWithTags";
import { i18n } from "../i18n/config";
import RegisterGoogleMap from "./RegisterGoogleMap";

function FAQ() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState([]);
  const [serviceType, setServiceType] = useState("farmer");
  const [language, setLanguage] = useState(i18n.language);
  const [formData, setFormData] = useState({
    farmAddress: "",
    coordinates: {
      lat: 0,
      lng: 0,
    },
    serviceType: "",
    serviceProviderOptions: [],
    gender: "",
    phoneNumber: "",
    name: "",
    dob: "",
  });

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: "",
      phoneNumber: "",
      serviceType: "",
      gender: "",
      farmAddress: "",
      dob: "",
      coordinates: {},
      serviceProviderOptions: [],
    },
  });
  const [userLocation, setUserLocation] = useState({
    lat: 11.5642373,
    lng: 104.9111526,
  });

  async function onSubmit(values) {
    const registerData = {
      ...formData,
      ...values,
    };
    registerData.serviceProviderOptions = selectedItems;
    registerData.serviceType = serviceType;
    try {
      const response = await axios.post(
        "https://farmheronewv1-production-4209.up.railway.app/v1/faq",
        registerData,
      );
      if (response.status === 201) {
        navigate("/login");
      }
    } catch (error) {
      alert("កំហុស");
    }
  }

  const setLocation = async (location) => {
    setUserLocation({ lat: location.lat, lng: location.lng });
    const address = await GetAddress(location.lat, location.lng);
    setValue("farmAddress", address);
    setValue("coordinates", { lat: location.lat, lng: location.lng });
  };

  const handleToggleView = () => {
    navigate("/");
  };
  const getUserLocation = async () => {
    // if geolocation is supported by the users browser
    if (navigator.geolocation) {
      // get the current users location
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          // save the geolocation coordinates in two variables
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;

          // update the value of userlocation variable
          setUserLocation({ lat, lng });
          setValue("coordinates", { lat: lat, lng: lng });
          setFormData({
            ...formData,
            ["coordinates"]: { lat: lat, lng: lng },
          });
          const address = await GetAddress(lat, lng);
          setValue("farmAddress", address);
          setFormData({
            ...formData,
            ["farmAddress"]: address,
          });
        },
        // if there was an error getting the users location
        (error) => {
          console.error("Error getting user location:", error);
        },
      );
    }
    // if geolocation is not supported by the users browser
    else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleMarkerPositionChange = async (newPosition) => {
    setValue("coordinates", { lat: newPosition.lat, lng: newPosition.lng });
    setFormData({
      ...formData,
      ["coordinates"]: { lat: newPosition.lat, lng: newPosition.lng },
    });
    const address = await GetAddress(newPosition.lat, newPosition.lng);
    setValue("farmAddress", address);
    setFormData({
      ...formData,
      ["farmAddress"]: address,
    });
  };

  const handleChangeLanguage = () => {
    if (language == "km") {
      i18n.changeLanguage("en");
      setLanguage("en");
    } else {
      i18n.changeLanguage("km");
      setLanguage("km");
    }
  };

  const margin = useBreakpointValue({ base: "3", md: "3" });
  const buttonStyles = {
    colorScheme: "yellow",
    size: "md",
    borderRadius: "md",
    _hover: {
      bg: "yellow.600",
    },
    transition: "ease-in-out",
    transitionDuration: "150ms",
  };

  useEffect(() => {
    getUserLocation();
  }, [navigate]);

  return (
    <Flex direction={{ base: "column", md: "row" }} w="100vw" h="100vh" gap={3}>
      <Center w={{ base: "full", md: "50%" }} mr={margin}>
        <Flex
          direction={"column"}
          gap={{ base: 3, md: 0 }}
          w={{ base: "full", md: "90%" }}
          m={2}
        >
          <Flex direction={"row"} justify={"space-between"}>
            <RouterLink to="/">
              <img
                src="/FarmHero__Logomark_Gold.png"
                width="90"
                height="60"
                alt=""
              />
            </RouterLink>
            <Box zIndex="tooltip">
              <Image
                src={language === "km" ? "/cambodia.png" : "/uk.png"}
                alt="Descriptive Alt Text"
                w="50px"
                h="30px"
                onClick={handleChangeLanguage}
              />
            </Box>
          </Flex>
          <Flex
            direction={{ base: "column", md: "row" }}
            gap={5}
            w="full"
            justify={"space-evenly"}
          >
            <VStack w={"full"}>
              <FormControl isInvalid={errors.farmAddress}>
                <FormLabel htmlFor="farmAddress">{t("address")}</FormLabel>
                <Input
                  id="farmAddress"
                  placeholder={t("address")}
                  {...register("farmAddress", {
                    required: i18n.t("required"),
                  })}
                />
                <FormErrorMessage>
                  {errors.farmAddress && errors.farmAddress.message}
                </FormErrorMessage>
              </FormControl>

              <RegisterGoogleMap
                center={userLocation}
                setUserLocation={handleMarkerPositionChange}
                onUserLocationChange={setLocation}
              />
              <br></br>
              <br></br>
              <FormControl>
                <FormLabel>{t("gender")}</FormLabel>
                <Select
                  id="gender"
                  {...register("gender", {
                    required: i18n.t("required"),
                  })}
                >
                  <option value={"male"}>{t("male")}</option>
                  <option value={"female"}>{t("female")}</option>
                  <option value={"other"}>{t("noShow")}</option>
                  <option value={"other"}>{t("other")}</option>
                </Select>
              </FormControl>
            </VStack>

            <VStack w={"full"}>
              <FormControl isInvalid={errors.name}>
                <FormLabel htmlFor="name">{t("full-name")}</FormLabel>
                <Input
                  id="name"
                  placeholder={t("full-name")}
                  {...register("name", {
                    required: i18n.t("required"),
                  })}
                />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.dob}>
                <FormLabel htmlFor="dob">{t("dob")}</FormLabel>
                <Input
                  id="dob"
                  type="date"
                  {...register("dob", {
                    required: i18n.t("required"),
                    validate: {
                      is18OrOlder: (value) => {
                        const today = new Date();
                        const birthDate = new Date(value);
                        const cutoffDate = new Date(
                          today.getFullYear() - 18,
                          today.getMonth(),
                          today.getDate(),
                        );

                        // Check if the birthDate is before the cutoffDate
                        return birthDate <= cutoffDate || i18n.t("least-18");
                      },
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.dob && errors.dob.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.phoneNumber}>
                <FormLabel htmlFor="phoneNumber">{t("phone-number")}</FormLabel>
                <Input
                  id="phoneNumber"
                  placeholder={t("phone-number")}
                  {...register("phoneNumber", {
                    required: i18n.t("required"),
                  })}
                />
                <FormErrorMessage>
                  {errors.phoneNumber && errors.phoneNumber.message}
                </FormErrorMessage>
              </FormControl>

              <RadioGroup defaultValue={"farmer"}>
                <Text>{t("i-am")}</Text>
                <HStack>
                  <Radio
                    {...register("serviceType")}
                    value={"farmer"}
                    size="lg"
                    onChange={() => {
                      setServiceType("farmer");
                    }}
                  >
                    <Text>{t("farmer")}</Text>
                  </Radio>
                  <Radio
                    {...register("serviceType")}
                    value={"serviceProvider"}
                    size="lg"
                    onChange={() => {
                      setServiceType("serviceProvider");
                    }}
                  >
                    <Text>{t("serviceProvider")}</Text>
                  </Radio>
                </HStack>
              </RadioGroup>

              {serviceType === "serviceProvider" && (
                <DropdownSelectWithTags
                  selectedItemsProp={selectedItems}
                  onSelectionChange={setSelectedItems}
                />
              )}
              <Button {...buttonStyles} onClick={handleSubmit(onSubmit)}>
                {t("register")}
              </Button>
              <div className="bottom-section bottomSectionContainer">
                <p>{t("existingUser")}</p>
                <a
                  className="Apple"
                  href="#register"
                  onClick={handleToggleView}
                >
                  {t("login")}
                </a>
              </div>
            </VStack>
          </Flex>{" "}
        </Flex>
      </Center>

      <Box
        w={{ base: "full", md: "50%" }}
        display={{ base: "none", md: "block" }}
      >
        <Image
          src="../Farmers.jpg"
          w={"full"}
          h={"full"}
          objectFit={"cover"}
          maxWidth="100%"
        />
      </Box>
      <Box position="fixed" bottom="5" right="5" zIndex="tooltip">
        <a href="https://t.me/+855975296777">
          <Image
            src="/telegram.png"
            alt="Descriptive Alt Text"
            w={{ md: "70px", base: "50px" }}
            h={{ md: "70px", base: "50px" }}
          />
        </a>
      </Box>
    </Flex>
  );
}

export default FAQ;
