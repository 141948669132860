import "./App.css";
import Home from "./Layout/Home";
import Register from "./Screens/Register";
import CreateBooking from "./Screens/CreateBooking";
import Login from "./Screens/Login";
import ViewDetails from "./Screens/viewDetails";
import ViewBooking from "./Screens/ViewBooking";
import ViewBookingDetails from "./Screens/ViewBookingDetails";
import UserProfile from "./Screens/UserProfile";
import EditProfile from "./Screens/EditProfile";
import FAQ from "./Screens/FAQ";
import ForgetPassword from "./Screens/ForgetPassword";
import NewPassword from "./Screens/NewPassword";
import { Route, Routes } from "react-router-dom";
import FarmerCreateBooking from "./Screens/FamerCreateBooking";
import RegisterNewCustomer from "./Screens/RegisterNewCustomer";

function App() {
  navigator.serviceWorker.register("/firebase-messaging-sw.js", {
    scope: "/firebase-cloud-messaging-push-scope",
  });
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/createBooking" element={<CreateBooking />} />
        <Route path="/registerNewCustomer" element={<RegisterNewCustomer />} />
        <Route path="/farmerBooking" element={<FarmerCreateBooking />} />
        <Route path="/viewBooking" element={<ViewBooking />} />
        <Route path="/viewDetails" element={<ViewDetails />} />
        <Route
          path="/bookingDetail/:bookingId"
          element={<ViewBookingDetails />}
        />
        <Route path="/userProfile/:userId" element={<UserProfile />} />
        <Route path="/editProfile" element={<EditProfile />} />
        <Route path="/waitlist" element={<FAQ />} />
        <Route path="/resetPassword" element={<ForgetPassword />} />
        <Route path="/:resetToken/newPassword" element={<NewPassword />} />
      </Routes>
    </>
  );
}

export default App;
