import React from "react";
import ForgetPasswordForm from "../Components/ForgetPasswordForm";
import GeneralNavigationbar from "../Components/GeneralNavigationBar";

const ForgetPassword = () => {
  return (
    <>
      <GeneralNavigationbar />
      <ForgetPasswordForm />
    </>
  );
};

export default ForgetPassword;
