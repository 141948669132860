import React from "react";
import "./Footer.css";
import linkedinIcon from "../linkedin (2).png"; // Replace with the actual path to your LinkedIn icon image
import phoneIcon from "../Phone call white.png"; // Replace with the actual path to your phone icon image
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  const linkedInURL = "https://www.linkedin.com/company/farm-hero/"; // Replace with your LinkedIn profile URL
  const phoneNumber = "+855 975 296 777"; // Replace with your phone number

  return (
    <footer className="footer">
      <span className="footer-link">
        <a href={linkedInURL} target="_blank" rel="noopener noreferrer">
          <img src={linkedinIcon} alt="LinkedIn" className="icon" />
          LinkedIn
        </a>
      </span>
      <span className="footer-phone">
        <img src={phoneIcon} alt="Phone" className="icon" />
        {t("phone-number")}: {phoneNumber}
      </span>
    </footer>
  );
}

export default Footer;
