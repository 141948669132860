import {
  Box,
  Heading,
  Divider,
  HStack,
  VStack,
  Text,
  Flex,
  Spacer,
  Tag,
  TagLabel,
  Wrap,
  WrapItem,
  Button,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import MainLayout from "../Layout/MainLayout";
import BookingService from "../Components/Services/BookingService";
import {
  getToken,
  isTokenExpired,
  clearToken,
  clearUserData,
} from "../Utilities/LocalStorage";
import { decodeToken } from "../Utilities/JsonDecode";
import { useQuery } from "@tanstack/react-query";
import UserService from "../Components/Services/UserService";

function UserProfile() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    data: user,
    isPending,
    isError,
    error,
  } = useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      const res = await UserService.get(`/${userId}`);
      return res.data;
    },
  });
  const { data: bookingRequest } = useQuery({
    queryKey: ["bookingRequest"],
    queryFn: async () => {
      const res = await BookingService.get(`/${userId}/bookingCount`);
      return res.data;
    },
  });

  const buttonStyles = {
    colorScheme: "yellow",
    size: "md",
    borderRadius: "md",
    _hover: {
      bg: "yellow.600",
    },
    transition: "ease-in-out",
    transitionDuration: "150ms",
  };

  const handleLogout = () => {
    clearUserData(decodeToken(getToken()));
    clearToken();
    navigate("/login");
  };

  const checkToken = async () => {
    const expired = isTokenExpired();
    if (expired === true) {
      navigate("/login");
    }
  };

  const handleEdit = () => {
    navigate("/editProfile");
  };

  const margin = useBreakpointValue({ base: "10", md: "5" });

  useEffect(() => {
    checkToken();
  }, []);

  if (isPending) {
    return <Heading>Loading...</Heading>;
  }

  if (isError) {
    return <Heading>Error: {error.message}</Heading>;
  }

  return (
    <MainLayout>
      {user && bookingRequest && (
        <>
          <Flex
            mr={margin}
            direction={{ md: "row", sm: "column", base: "column" }}
          >
            <Box
              borderWidth="2px"
              borderRadius="lg"
              overflow="hidden"
              p={6}
              m={5}
              w={{ base: "full", sm: "full", md: "full" }}
            >
              <VStack justify="space-between">
                <Image
                  borderRadius="70&"
                  boxSize="150px"
                  src="/FarmHero__Brandmark_Gold.png"
                  alt="Dan Abramov"
                  objectFit={"cover"}
                />
                <VStack justify="space-between">
                  <HStack>
                    <Text fontWeight="bold">{t("full-name")}:</Text>
                    <Text>{user.name}</Text>
                  </HStack>
                  <HStack>
                    <Text fontWeight="bold">{t("phone-number")}:</Text>
                    <Text>{user.phoneNumber}</Text>
                  </HStack>
                  <HStack>
                    <Text fontWeight="bold">{t("gender")}:</Text>
                    <Tag colorScheme="blue" borderRadius="full" size="lg">
                      <TagLabel>{t(`${user.gender}`)}</TagLabel>
                    </Tag>
                  </HStack>
                  <HStack>
                    <Text fontWeight="bold">{t("address")}:</Text>
                    <Text>{user.farmAddress}</Text>
                  </HStack>
                  <Button {...buttonStyles} onClick={handleEdit}>
                    {t("edit-profile")}
                  </Button>
                </VStack>
              </VStack>
            </Box>
            <Spacer />
            <Flex
              m={5}
              direction={"column"}
              w={{ base: "full", sm: "full", md: "full" }}
            >
              <Box
                borderWidth="2px"
                borderRadius="lg"
                overflow="hidden"
                p={6}
                // m={5}
                w={{ base: "full", sm: "full", md: "full" }}
              >
                <VStack align="stretch">
                  <Heading as="h2" size="lg">
                    {user.serviceType === "farmer"
                      ? t("farmer-detail")
                      : t("sp-detail")}
                  </Heading>
                  <Divider />
                  <HStack justify="space-between">
                    <Text fontWeight="bold">{t("service-type")}:</Text>
                    <Tag colorScheme="green" borderRadius="full" size="lg">
                      <TagLabel>{t(`${user.serviceType}`)}</TagLabel>
                    </Tag>
                  </HStack>
                  {user.serviceType === "farmer" ? (
                    <HStack justify="space-between">
                      <Text>{t("total-booking")}</Text>
                      <Text fontWeight="bold">{bookingRequest.counts}</Text>
                    </HStack>
                  ) : null}
                </VStack>
              </Box>
              {user.serviceType === "serviceProvider" && (
                <Box
                  borderWidth="2px"
                  borderRadius="lg"
                  overflow="hidden"
                  p={6}
                  mt={{ base: 5, md: 5 }}
                  w={{ base: "full", sm: "full", md: "full" }}
                >
                  <VStack align="stretch">
                    <Heading as="h2" size="lg">
                      Booking Statistics
                    </Heading>
                    <Divider />
                    <HStack justify="space-between">
                      <Text fontWeight="bold">{t("service-type")}:</Text>
                      <Tag colorScheme="green" borderRadius="full" size="lg">
                        <TagLabel>{t(`${user.serviceType}`)}</TagLabel>
                      </Tag>
                    </HStack>
                    <HStack justify="space-between">
                      <Text fontWeight="bold">{t("equipment-type")}:</Text>
                      <Wrap>
                        {user.serviceProviderOptions.map((equ, index) => (
                          <WrapItem key={index}>
                            <Tag
                              colorScheme="orange"
                              borderRadius="full"
                              size="lg"
                              variant="subtle"
                            >
                              <TagLabel>
                                {t(`${equ.type}`)} - {equ.quantity}
                              </TagLabel>
                            </Tag>
                          </WrapItem>
                        ))}
                      </Wrap>
                    </HStack>
                    {user.serviceType === "farmer" ? (
                      <HStack justify="space-between">
                        <Text>{t("total-booking")}</Text>
                        <Text fontWeight="bold">{bookingRequest.counts}</Text>
                      </HStack>
                    ) : (
                      <>
                        <HStack justify="space-between">
                          <Text>{t("total-accept")}</Text>
                          <Text fontWeight="bold">{bookingRequest.counts}</Text>
                        </HStack>
                        <HStack justify="space-between">
                          <Text>{t("total-money")}</Text>
                          <Text fontWeight="bold">
                            {bookingRequest.totalMoneyMade}
                          </Text>
                        </HStack>
                      </>
                    )}
                  </VStack>
                </Box>
              )}
            </Flex>
          </Flex>
          <Button
            {...buttonStyles}
            onClick={handleLogout}
            w={{ base: "20%", md: "10%" }}
            ml={5}
          >
            {t("logout")}
          </Button>
          <Box position="fixed" bottom="5" right="5" zIndex="tooltip">
            <a href="https://t.me/+855975296777">
              <Image
                src="/telegram.png"
                alt="Descriptive Alt Text"
                w={{ md: "70px", base: "50px" }}
                h={{ md: "70px", base: "50px" }}
              />
            </a>
          </Box>
        </>
      )}
    </MainLayout>
  );
}

export default UserProfile;
