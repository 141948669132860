import React, { useEffect, useState } from "react";
import { getToken } from "../Utilities/LocalStorage";
import { decodeToken } from "../Utilities/JsonDecode";
import UserService from "./Services/UserService";
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Divider,
  Flex,
  Button,
  Link,
} from "@chakra-ui/react";
import BookingService from "./Services/BookingService";
import { useTranslation } from "react-i18next";

const AggregateUserDetails = ({ booking, serviceType }) => {
  const { t } = useTranslation();
  const [userDetails, setUserDetails] = useState([]);
  const [booked, setBooked] = useState(false);
  const props = { ...booking };
  const [currentUser, setCurrentUser] = useState(null);
  const [bookingData, setBookingData] = useState(null);

  const handleServiceCompleted = async () => {
    try {
      await BookingService.patch(`/${booking.id}/serviceCompleted`, {
        acceptanceID: props.acceptanceID,
        serviceCompleted: true,
      });
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  const handlePayment = async () => {
    try {
      await BookingService.patch(`/${booking.id}/paymentConfirmed`, {
        acceptanceID: props.acceptanceID,
        paymentConfirmed: true,
      });
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  const handleCancel = async () => {
    console.log("handleCancel");
  };

  const fetchUserDetails = async () => {
    const token = getToken();
    const id = decodeToken(token);
    try {
      const user = await UserService.get(`/${id}`);
      setCurrentUser(user.data);
      if (user) {
        const userServiceType = user.data.serviceType;
        if (props.acceptanceID === "") {
          setBooked(false);
        } else {
          if (userServiceType === "farmer") {
            let detailsId = props.acceptanceID;
            const fetchedUserDetails = await UserService.get(`/${detailsId}`);
            //   setUserDetails(fetchedUserDetails.data);
            userDetails.push({
              userData: fetchedUserDetails.data,
              farmAddress: fetchedUserDetails.data.farmAddress,
              coordinates: fetchedUserDetails.data.coordinates,
            });
            setBooked(true);
          }
          if (userServiceType === "serviceProvider") {
            let users = props.subBookings;
            for (const item of users) {
              console.log("items", item);
              let detailsId = item.customerId;
              let bookingId = item.id;
              const fetchedUserDetails = await UserService.get(`/${detailsId}`);
              const fetchedBookingDetails = await BookingService.get(
                `/bookingDetail/${bookingId}`,
              );
              //   setUserDetails(fetchedUserDetails.data);
              userDetails.push({
                userData: fetchedUserDetails.data,
                bookingData: fetchedBookingDetails.data,
                farmAddress: item.farmAddress,
                coordinates: item.coordinates,
              });
            }
            setBooked(true);
          }
        }
      }
    } catch (error) {
      console.error("Failed to fetch user details:", error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []); // You might need to adjust this dependency array based on your needs

  return (
    <>
      {booked && props.bookingConfirmed ? (
        <Flex direction={"column"} w="full">
          {userDetails.map((data) => (
            <Box
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              p={6}
              m={6}
              key={data.userData.id}
            >
              <VStack align="stretch" spacing={4}>
                <Heading as="h3" size="lg">
                  {serviceType === "farmer"
                    ? t("sp-detail")
                    : t("farmer-detail")}
                </Heading>
                <Divider />
                {/* Example detail display, adjust according to your data structure */}
                <HStack justify="space-between">
                  <Text fontWeight="bold">{t("full-name")}:</Text>
                  <Text>{data.userData.name}</Text>
                </HStack>
                <HStack justify="space-between">
                  <Text fontWeight="bold">{t("phone-number")}:</Text>
                  <Text>{data.userData.phoneNumber}</Text>
                </HStack>
                <HStack justify="space-between">
                  <Text fontWeight="bold">{t("gender")}:</Text>
                  <Text>{data.userData.gender}</Text>
                </HStack>
                <HStack justify="space-between">
                  <Text fontWeight="bold">{t("request-date")}:</Text>
                  <Text>{data.bookingData.preferredDate}</Text>
                </HStack>
                <HStack justify="space-between">
                  <Text fontWeight="bold">{t("number-hectare")}:</Text>
                  <Text>{data.bookingData.numberOfHectare}</Text>
                </HStack>
                <HStack justify="space-between">
                  <Text fontWeight="bold">{t("address")}:</Text>
                  <Link
                    isExternal
                    href={`http://maps.google.com?q=${data.coordinates.lat},${data.coordinates.lng}`}
                  >
                    {data.farmAddress}
                  </Link>
                </HStack>
                {/* More details displayed here */}
              </VStack>
            </Box>
          ))}
          {props.bookingConfirmed &&
            currentUser.serviceType === "farmer" &&
            !props.serviceCompleted && (
              <Box
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                p={6}
                m={6}
              >
                <VStack align="stretch" spacing={4}>
                  <Heading as="h3" size="lg">
                    {t("service-completed")}
                  </Heading>
                  <Divider />
                  <HStack>
                    <Button
                      onClick={handleServiceCompleted}
                      colorScheme="green"
                    >
                      Confirmed
                    </Button>
                    {/* <Button onClick={handleCancel} colorScheme="red">
                      Cancel
                    </Button> */}
                  </HStack>
                  {/* More details displayed here */}
                </VStack>
              </Box>
            )}
          {props.bookingConfirmed &&
            currentUser.serviceType === "serviceProvider" &&
            props.serviceCompleted &&
            !props.paymentConfirmed && (
              <Box
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                p={6}
                m={6}
              >
                <VStack align="stretch" spacing={4}>
                  <Heading as="h3" size="lg">
                    {t("complete-payment")}
                  </Heading>
                  <Divider />
                  <HStack>
                    <Button onClick={handlePayment} colorScheme="green">
                      Confirmed
                    </Button>
                    {/* <Button onClick={handleCancel} colorScheme="red">
                      Cancel
                    </Button> */}
                  </HStack>
                  {/* More details displayed here */}
                </VStack>
              </Box>
            )}
        </Flex>
      ) : (
        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          p={6}
          m={6}
          w="full"
        >
          <VStack align="stretch" spacing={4}>
            <Heading as="h3" size="lg">
              {serviceType === "farmer" ? t("sp-detail") : t("farmer-detail")}
            </Heading>
            <Divider />
            <Text>{t("no-acceptance")}</Text>
          </VStack>
        </Box>
      )}
    </>
  );
};

export default AggregateUserDetails;
