import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  IconButton,
  Link,
  Stack,
  Button,
  useDisclosure,
  useBreakpointValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Center,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  AddIcon,
  ExternalLinkIcon,
  AtSignIcon,
  QuestionIcon,
} from "@chakra-ui/icons";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { clearToken, clearUserData, getToken } from "../Utilities/LocalStorage";
import { decodeToken, decodeTokenObject } from "../Utilities/JsonDecode";
import { i18n } from "../i18n/config";
// import InstallPWA from "./InstallPWA";
import { isTokenExpired } from "../Utilities/LocalStorage";

function Navigationbar() {
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();
  const [language, setLanguage] = useState(i18n.language);
  const { t } = useTranslation();
  const [login, setLogin] = useState(false);
  const [st, setSt] = useState();

  const checkProfile = () => {
    const token = getToken();
    const id = decodeToken(token);
    navigate(`/userProfile/${id}`);
  };

  const handleLogout = () => {
    clearUserData(decodeToken(getToken()));
    clearToken();
    navigate("/login");
  };

  const handleLogin = () => {
    clearToken();
    navigate("/login");
  };

  const handleChangeLanguage = () => {
    if (language == "km") {
      i18n.changeLanguage("en");
      setLanguage("en");
    } else {
      i18n.changeLanguage("km");
      setLanguage("km");
    }
  };

  const checkToken = () => {
    try {
      const expired = isTokenExpired();
      if (expired === true) {
        setLogin(false);
      } else {
        const token = getToken();
        const tok = decodeTokenObject(token);
        setSt(tok);
        setLogin(true);
      }
    } catch (e) {
      navigate("/login");
    }
  };

  useEffect(() => {
    checkToken();
  });

  // Optional: Use a Chakra UI hook to handle the display value based on the breakpoint
  const displayValue = useBreakpointValue({ base: "none", md: "flex" });

  const buttonStyles = {
    colorScheme: "yellow",
    size: "md",
    borderRadius: "md",
    _hover: {
      bg: "yellow.600",
    },
    transition: "ease-in-out",
    transitionDuration: "150ms",
  };

  return (
    <Flex
      bg={"#0a1e40"}
      align="center"
      justify="space-between"
      wrap="wrap"
      p={2}
    >
      <RouterLink to="/">
        <img src="/FarmHero__Logomark_Gold.png" width="90" height="60" alt="" />
      </RouterLink>

      {/* Mobile menu button */}
      <IconButton
        size="md"
        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
        aria-label="Open Menu"
        display={{ md: "none" }}
        onClick={onToggle}
        {...buttonStyles}
      />

      {/* Desktop Menu */}
      <Box display={displayValue} flexBasis={{ base: "100%", md: "auto" }}>
        <Stack
          spacing={8}
          align="center"
          justify={["center", "space-between", "flex-end", "flex-end"]}
          direction={["column", "row", "row", "row"]}
          pt={[4, 4, 0, 0]}
        >
          <Image
            src={language === "km" ? "/cambodia.png" : "/uk.png"}
            alt="Descriptive Alt Text"
            w="50px"
            h="30px"
            onClick={handleChangeLanguage}
          />
          {/* <InstallPWA /> */}

          {login && (
            <>
              {st === "farmer" && (
                <Button
                  {...buttonStyles}
                  onClick={() => navigate("/FarmerBooking")}
                >
                  {t("booking")}
                </Button>
              )}
              {st === "bookingAgent" && (
                <Button
                  {...buttonStyles}
                  onClick={() => navigate("/createBooking")}
                >
                  {t("booking")}
                </Button>
              )}

              <Button
                {...buttonStyles}
                onClick={() => navigate("/viewBooking")}
              >
                {t("viewBooking")}
              </Button>
            </>
          )}
          <Menu>
            <MenuButton as={Button} {...buttonStyles}>
              <Center>
                <Image
                  src="/updated_image.png" // Path to your image
                  alt="Descriptive Alt Text"
                  boxSize={"50px"}
                />
              </Center>
            </MenuButton>
            <MenuList zIndex={2}>
              {login === true ? (
                <>
                  <MenuItem icon={<AddIcon />} onClick={checkProfile}>
                    {t("profile")}
                  </MenuItem>
                  <MenuItem icon={<ExternalLinkIcon />} onClick={handleLogout}>
                    {t("logout")}
                  </MenuItem>
                </>
              ) : (
                <>
                  <MenuItem icon={<ExternalLinkIcon />} onClick={handleLogin}>
                    {t("login")}
                  </MenuItem>
                  <MenuItem
                    icon={<QuestionIcon />}
                    onClick={() => navigate("/waitlist")}
                  >
                    {t("waitlist")}
                  </MenuItem>
                </>
              )}
            </MenuList>
          </Menu>
        </Stack>
      </Box>

      {/* Mobile Menu */}
      <Box
        display={{ base: isOpen ? "block" : "none", md: "none" }}
        flexBasis={{ base: "100%", md: "auto" }}
      >
        <Stack
          spacing={4}
          align="center"
          justify={["center", "space-between", "flex-end", "flex-end"]}
          direction={["column", "row", "row", "row"]}
          pt={[4, 4, 0, 0]}
        >
          <Box>
            <Image
              src={language === "km" ? "/cambodia.png" : "/uk.png"}
              alt="Descriptive Alt Text"
              w="50px"
              h="30px"
              onClick={handleChangeLanguage}
            />
          </Box>
          {/* <InstallPWA /> */}
          {login === true ? (
            <>
              {st === "farmer" && (
                <Button
                  {...buttonStyles}
                  onClick={() => navigate("/FarmerBooking")}
                >
                  {t("booking")}
                </Button>
              )}
              {st === "bookingAgent" && (
                <Button
                  {...buttonStyles}
                  onClick={() => navigate("/createBooking")}
                >
                  {t("booking")}
                </Button>
              )}
              <Button
                {...buttonStyles}
                onClick={() => navigate("/viewBooking")}
              >
                {t("viewBooking")}
              </Button>
              <Button {...buttonStyles} onClick={checkProfile}>
                {t("profile")}
              </Button>
              <Button {...buttonStyles} onClick={handleLogout}>
                {t("logout")}
              </Button>
            </>
          ) : (
            <>
              <Button {...buttonStyles} onClick={handleLogin}>
                {t("login")}
              </Button>
              <Button {...buttonStyles} onClick={() => navigate("/waitlist")}>
                {t("waitlist")}
              </Button>
            </>
          )}
        </Stack>
      </Box>
    </Flex>
  );
}

export default Navigationbar;
