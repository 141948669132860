import React, { useEffect, useState } from "react";
import { getToken } from "../Utilities/LocalStorage";
import { decodeToken, decodeTokenObject } from "../Utilities/JsonDecode";
import UserService from "./Services/UserService";
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Divider,
  Button,
  Tag,
  TagLabel,
  Link,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const BookingDetails = ({ booking }) => {
  //   const { hasCopied, onCopy } = useClipboard(booking.txHash);
  const navigate = useNavigate();
  const data = { ...booking };
  const { t } = useTranslation();
  const [serviceType, setServiceType] = useState("");
  const [name, setName] = useState("");
  const buttonStyles = {
    colorScheme: "yellow",
    size: "md",
    borderRadius: "md",
    _hover: {
      bg: "yellow.600",
    },
    transition: "ease-in-out",
    transitionDuration: "150ms",
  };

  // function handleEdit() {
  //   navigate(`/editBooking/${data.id}`, { state: data });
  // }

  const getUserRole = async () => {
    const token = getToken();
    const serviceType = decodeTokenObject(token);
    setServiceType(serviceType);
  };

  // const getUsername = async () => {
  //   try {
  //     if (data.preferredSP && data.preferredSP.id !== "") {
  //       const user = await UserService.get(`/${data.preferredSP.id}`);
  //       if (user) {
  //         setName(user.data.name);
  //       }
  //     } else {
  //       setName("")
  //     }

  //   } catch (e) {
  //     console.error("Failed to fetch user")
  //   }
  // }

  useEffect(() => {
    getUserRole();
  }, []);

  // useEffect(() => {
  //   getUsername();
  // }, []);

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={6}
      m={6}
      w={{ md: "full" }}
    >
      <VStack align="stretch" spacing={4}>
        <Heading as="h3" size="lg">
          {t("booking-detail")}
        </Heading>
        <Divider />
        <HStack justify="space-between">
          <Text fontWeight="bold">{t("confirm-booking")}:</Text>
          {data.bookingConfirmed ? (
            <Tag colorScheme="blue" borderRadius="full" size="lg">
              <TagLabel>{t("accepted")}</TagLabel>
            </Tag>
          ) : (
            <Tag colorScheme="yellow" borderRadius="full" size="lg">
              <TagLabel>{t("not-accepted")}</TagLabel>
            </Tag>
          )}
        </HStack>
        <HStack justify="space-between">
          <Text fontWeight="bold">{t("service-completed")}:</Text>
          {data.serviceCompleted ? (
            <Tag colorScheme="blue" borderRadius="full" size="lg">
              <TagLabel>{t("yes")}</TagLabel>
            </Tag>
          ) : (
            <Tag colorScheme="yellow" borderRadius="full" size="lg">
              <TagLabel>{t("no")}</TagLabel>
            </Tag>
          )}
        </HStack>
        <HStack justify="space-between">
          <Text fontWeight="bold">{t("payment-confirm")}:</Text>
          {data.paymentConfirmed ? (
            <Tag colorScheme="blue" borderRadius="full" size="lg">
              <TagLabel>{t("completed")}</TagLabel>
            </Tag>
          ) : (
            <Tag colorScheme="yellow" borderRadius="full" size="lg">
              <TagLabel>{t("not-completed")}</TagLabel>
            </Tag>
          )}
        </HStack>

        <HStack justify="space-between">
          <Text fontWeight="bold">{t("address")}:</Text>
          <Link
            isExternal
            href={`http://maps.google.com?q=${data.coordinates.lat},${data.coordinates.lng}`}
          >
            {data.farmAddress}
          </Link>
        </HStack>
        {serviceType === "farmer" && (
          <>
            <HStack justify="space-between">
              <Text fontWeight="bold">{t("farmer-name")}:</Text>
              <Text>{data.name}</Text>
            </HStack>
            <HStack justify="space-between">
              <Text fontWeight="bold">{t("farmer-phone-number")}:</Text>
              <Text>{data.customerPhoneNumber}</Text>
            </HStack>
          </>
        )}
        <HStack justify="space-between">
          <Text fontWeight="bold">{t("request-date")}:</Text>
          <Text>{data.preferredDate}</Text>
        </HStack>
        <HStack justify="space-between">
          <Text fontWeight="bold">{t("payment-date-to-farm-hero")}:</Text>
          <Text>{data?.preferredPaymentDate}</Text>
        </HStack>
        {data?.cropType && (
          <HStack justify="space-between">
            <Text fontWeight="bold">{t("crop-type")}:</Text>
            <Text>{t(`${data.cropType}`)}</Text>
          </HStack>
        )}
        <HStack justify="space-between">
          <Text fontWeight="bold">{t("equipment-type")}:</Text>
          <Text>{t(`${data.serviceType}`)}</Text>
        </HStack>
        {data.serviceType === "LAND_LEVELER" && (
          <HStack justify="space-between">
            <Text fontWeight="bold">{t("total-hour")}:</Text>
            <Text>{t(`${data?.totalHour}`)}</Text>
          </HStack>
        )}
        <HStack justify="space-between">
          <Text fontWeight="bold">{t("land-type")}:</Text>
          <Text>{t(`${data.soilType}`)}</Text>
        </HStack>
        <HStack justify="space-between">
          <Text fontWeight="bold">{t("number-hectare")}:</Text>
          <Text>{data.numberOfHectare}</Text>
        </HStack>
        <HStack justify="space-between">
          <Text fontWeight="bold">{t("total-price")}:</Text>
          <Text>{data.estimatedCost} KHR</Text>
        </HStack>
        {data.preferredSP ? (
          <HStack justify="space-between">
            <Text fontWeight="bold">{t("prefer-sp")}:</Text>
            <Text>{data.preferredSP.name}</Text>
          </HStack>
        ) : null}
      </VStack>
      {/* {serviceType === "farmer" && !data.serviceCompleted && (
        <Button {...buttonStyles} onClick={handleEdit}>
          {t("edit")}
        </Button>
      )} */}
    </Box>
  );
};

export default BookingDetails;
