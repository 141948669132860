import React from "react";
import EnterNewPasswordForm from "../Components/EnterNewPasswordForm";
import GeneralNavigationbar from "../Components/GeneralNavigationBar";

const NewPassword = () => {
  return (
    <>
      <GeneralNavigationbar />
      <EnterNewPasswordForm />;
    </>
  );
};

export default NewPassword;
