import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import "../Screens/Login.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

const EnterNewPasswordForm = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [confirmPassword, setConfirmPassword] = useState("");
  const toast = useToast();
  let { resetToken } = useParams();

  const buttonStyles = {
    colorScheme: "yellow",
    size: "md",
    borderRadius: "md",
    _hover: {
      bg: "yellow.600",
    },
    transition: "ease-in-out",
    transitionDuration: "150ms",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast({
        title: "Error",
        description: "Passwords do not match.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    // Here, you'd make an API call to your backend to reset the password
    try {
      await axios.post(
        `https://farmheronewv1-production-4209.up.railway.app/v1/auth/reset-password?token=${resetToken}`,
        {
          password,
        }
      );
      toast({
        title: "Password Updated",
        description: "Your password has been successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/login");
    } catch {
      toast({
        title: "Error, reseting password.",
        description: "This user doesn't exist",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box my={4} textAlign="left">
      <form onSubmit={handleSubmit}>
        <FormControl isRequired>
          <FormLabel>{t("new-password")}</FormLabel>
          <Input
            type="password"
            placeholder={t("enter-password")}
            onChange={(e) => setPassword(e.target.value)}
          />
          <p className="password-instruction">
            {/* Password must be at least 8 characters long. */}
            {t("passwordValid")}
          </p>
        </FormControl>
        <FormControl isRequired mt={4}>
          <FormLabel>{t("confirm-password")}</FormLabel>
          <Input
            type="password"
            placeholder={t("confirm-password")}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <p className="password-instruction">
            {/* Password must be at least 8 characters long. */}
            {t("match-pass")}
          </p>
        </FormControl>
        <Button width="full" mt={4} type="submit" {...buttonStyles}>
          {t("finish")}
        </Button>
      </form>
    </Box>
  );
};

export default EnterNewPasswordForm;
