import React from "react";

import {
  Box,
  Heading,
  Text,
  Flex,
  Button,
  HStack,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const getColorScheme = (state) => {
  switch (state) {
    case "merged":
      return "blue";
    case "waiting":
      return "yellow";
    case "requested":
      return "green";
    case "confirmed":
      return "cyan";
    case "aggregated":
      return "red";
    case "completed":
      return "teal";
    case "paid":
      return "purple";
    default:
      return "gray"; // Default color scheme
  }
};

const BookingDetailCard = ({ booking, serviceType }) => {
  const navigate = useNavigate();
  const data = { ...booking };
  const { t } = useTranslation();

  const handleMove = (id) => {
    navigate(`/bookingDetail/${id}`);
  };

  const colorScheme = getColorScheme(data.state);

  return (
    <Box p={6} m={5} bg="gray.100" borderRadius="lg" boxShadow="base">
      <Flex direction="column" gap="2">
        <Heading as="h3" size="md" color="blue.900">
          {t("equipment-type")}:
        </Heading>
        <Text fontSize="lg" as={"i"}>
          {t(`${data.serviceType}`)}
        </Text>
        <HStack justify="space-between">
          <Text>{t("date")}:</Text>
          <Text>{data.preferredDate}</Text>
        </HStack>
        <HStack justify="space-between">
          <Text>{t("farmer-name")}:</Text>
          <Text>{data.customerDetails.name}</Text>
        </HStack>
        <HStack justify="space-between">
          <Text>{t("number-hectare")}:</Text>
          <Text>
            {data.numberOfHectare} {t("hectare")}
          </Text>
        </HStack>
        <HStack justify="space-between">
          <Text>{t("total-price")}:</Text>
          <Text>{data.estimatedCost.toLocaleString("en-US")} ៛</Text>
        </HStack>
        <HStack justify="space-between">
          <Text>{t("status")}:</Text>
          <Tag
            size={"md"}
            borderRadius="full"
            variant="solid"
            colorScheme={colorScheme}
          >
            {/* TODO: remove after payment flow approved */}
            {data.bookingConfirmed && !data.serviceCompleted ? (
              <TagLabel color={"white"}>{t("booking-confirm")}</TagLabel>
            ) : (
              <TagLabel color={"white"}>{t(`${data.state}`)}</TagLabel>
            )}
            {/* TODO: Will uncomment back after new payment flow approved */}
            {/* <TagLabel color={"white"}>
              {serviceType === "bookingAgent" &&
              (data.bookingStatus === "ba_fh" ||
                data.bookingStatus === "payment_completed" ||
                data.bookingStatus === "fh_sp")
                ? t("ba_fh")
                : serviceType === "farmer" &&
                    (data.bookingStatus === "farmer_fh" ||
                      data.bookingStatus === "payment_completed" ||
                      data.bookingStatus === "fh_sp")
                  ? t("farmer_fh")
                  : t(`${data.bookingStatus}`)}
            </TagLabel>{" "} */}
          </Tag>
        </HStack>
        {data.edited && (
          <HStack justify="space-between">
            <Text>{t("edited")}:</Text>
            <Tag
              size={"md"}
              borderRadius="full"
              variant="solid"
              colorScheme="cyan"
            >
              <TagLabel color={"white"}>{t("true")}</TagLabel>
            </Tag>
          </HStack>
        )}

        <Button colorScheme="yellow" mt="4" onClick={() => handleMove(data.id)}>
          {t("more-details")}
        </Button>
      </Flex>
    </Box>
  );
};

export default BookingDetailCard;
