import React from "react";
import "./ImageSlider.css";
import { useTranslation } from "react-i18next";
function ImageSlider() {
  const { t } = useTranslation();
  return (
    <div id="carouselExampleFade" className="carousel slide carousel-fade">
      <div className="carousel-inner">
        {/* TODO: remove these 2 images for now optimization purpose */}
        {/* <div className="carousel-item active">
          <img
            src="../randy.jpg"
            className="d-block w-100"
            style={{ width: 500 + "px" }}
            alt="..."
          />
          <div className="carousel-caption">
            <h5>{t("welcome-to-fh")}​</h5>
          </div>
        </div> */}
        {/* <div className="carousel-item">
          <img
            src="../sidara-keo-W8L7WKSgPhA-unsplash.jpg"
            className="d-block w-100"
            alt="..."
          />
          <div className="carousel-caption">
            <h5>{t("list-tractor")}​</h5>
          </div>
        </div> */}
        <div className="carousel-item active">
          <img
            src="../compressed-tractor.jpg"
            className="d-block w-100"
            alt="..."
          />
          <div className="carousel-caption">
            <h5>{t("schedule-service")}​</h5>
            {/*Schedule your tractor service earlier or when you need it!*/}
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleFade"
        data-bs-slide="prev"
      >
        {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
        {/* <span className="visually-hidden">Previous</span> */}
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleFade"
        data-bs-slide="next"
      >
        {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
        {/* <span className="visually-hidden">Next</span> */}
      </button>
    </div>
  );
}

export default ImageSlider;
