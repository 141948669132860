import React, { useState, useEffect } from "react";
import MainLayout from "../Layout/MainLayout";
import "./ViewBookingPage.css";
import { useNavigate } from "react-router-dom";
import { decodeToken, decodeTokenObject } from "../Utilities/JsonDecode";
import BookingService from "../Components/Services/BookingService";
import UserService from "../Components/Services/UserService";
import { getToken, isTokenExpired } from "../Utilities/LocalStorage";
import { useTranslation } from "react-i18next";
import {
  Text,
  Select,
  HStack,
  SimpleGrid,
  useBreakpointValue,
  Spinner,
  Flex,
} from "@chakra-ui/react";
import BookingDetailCard from "../Components/BookingDetailCard";
import { initializeNotification } from "../Components/Utilities/notification";
import { useQuery } from "@tanstack/react-query";

const ViewBooking = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [serviceType, setServiceType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filterType, setFilterType] = useState("");
  const [filterBook, setFilterBook] = useState("");
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [userData, setUserData] = useState(null);
  const [st, setSt] = useState(null);
  const [filterValue, setFilterValue] = useState("all");
  const [rangeFilterData, setRangeFilterData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const columnCount = useBreakpointValue({ base: 1, md: 3 });

  const messages = {
    allBookings: "no-new-booking",
    specific: "no-new-booking",
    reconfirm: "no-reconfirm-booking",
    confirmedBookings: "no-confirm-booking",
    completedBookings: "no-completed-booking",
  };

  const messageKey = messages[filterType] || "no-new-booking";

  const fetchUserData = async () => {
    const token = getToken();
    const id = decodeToken(token);
    const service = decodeTokenObject(token);
    setSt(service);
    setFilterType(service === "serviceProvider" ? "specific" : "allBookings");
    setFilterBook(service === "serviceProvider" ? "specific" : "allBookings");
    const user = await UserService.get(`/${id}`);
    if (user) {
      initializeNotification(id, user.data);
      setUserData(user.data);
      const userServiceType = user.data.serviceType;

      setServiceType(userServiceType);

      if (
        userServiceType === "serviceProvider" ||
        userServiceType === "farmer"
      ) {
        navigate("/viewBooking");
      } else {
        console.log("Unknown service type:", userServiceType);
      }
    } else {
      setLoading(false);
    }
  };

  const {
    isPending,
    isSuccess,
    data: bookings,
  } = useQuery({
    queryKey: ["bookings", userId, serviceType, filterType],
    queryFn: async () => {
      try {
        if (serviceType) {
          let response = await BookingService.get(`/${userId}`, {
            params: {
              serviceType: serviceType,
              filterType: filterType,
            },
          });
          return response.data || [];
        }
      } catch {}
    },
    enabled: !!userId && !!serviceType && !!filterType, // Only run the query if all three are truthy
  });

  useEffect(() => {
    if (isPending) {
      setLoading(true);
    }
    if (isSuccess) {
      setLoading(false);
    }
  }, [isSuccess, isPending]);

  const checkToken = async () => {
    try {
      const expired = isTokenExpired();
      if (expired === true) {
        navigate("/login");
      } else {
        getAccessToken();
      }
    } catch (e) {
      navigate("/login");
    }
  };

  const getAccessToken = () => {
    const token = getToken();
    if (token !== null) {
      const userId = decodeToken(token);
      setUserId(userId);
      fetchUserData();
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  useEffect(() => {
    if (userData !== null) {
      if (userData === "serviceProvider") {
        setRangeFilterData(bookings || []);
      } else {
        setRangeFilterData(bookings || []);
      }
    }
  }, [userData, bookings]);

  useEffect(() => {
    if (filterValue === "all") {
      setFilteredData(rangeFilterData);
    } else {
      // Filter the data based on the selected filter value
      const filtered = rangeFilterData.filter(
        (booking) => booking.serviceType === filterValue,
      );
      setFilteredData(filtered);
    }
  }, [rangeFilterData, filterValue]);

  const handleFilter = (type) => {
    setFilterType(type);
    setFilterBook(type);
  };

  const handleChangeFilter = (event) => {
    setFilterValue(event.target.value);
  };

  return (
    <MainLayout>
      <div className="view-booking-page">
        <h2>{t("view-booking")}</h2>
        <div className="filter-buttons" role="group" aria-label="Basic example">
          {st === "serviceProvider" ? (
            <button
              className={`filter-button ${
                filterBook === "specific" ? "active" : ""
              }`}
              onClick={() => handleFilter("specific")}
            >
              {t("all-booking")}
            </button>
          ) : (
            <button
              className={`filter-button ${
                filterBook === "allBookings" ? "active" : ""
              }`}
              onClick={() => handleFilter("allBookings")}
            >
              {t("all-booking")}
            </button>
          )}
          <button
            className={`filter-button ${
              filterBook === "confirmedBookings" ? "active" : ""
            }`}
            onClick={() => handleFilter("confirmedBookings")}
          >
            {t("booking-accepted")}
          </button>
          {/* TODO: hide new payment flow for now */}
          {/* <button
            className={`filter-button ${
              filterBook === "completedBookings" ? "active" : ""
            }`}
            onClick={() => handleFilter("completedBookings")}
          >
            {t("service-completed")}
          </button> */}
          <button
            className={`filter-button ${
              filterBook === "paymentCompleted" ? "active" : ""
            }`}
            onClick={() => handleFilter("paymentCompleted")}
          >
            {t("complete-booking")}
          </button>
        </div>
        {/* {st === "serviceProvider" && (
          <HStack>
            <Text>Special Request</Text>
            <Switch
              onChange={(e) => {
                if (e.target.checked === false) {
                  setFilterType("allBookings");
                } else if (e.target.checked === true) {
                  setFilterType("specific");
                }
              }}
            />
          </HStack>
        )} */}
        <HStack>
          <Text>Filter</Text>
          <Select
            size={"lg"}
            onChange={handleChangeFilter}
            defaultChecked={"all"}
          >
            <option value="all"></option>
            <option value="RICE_NO_TILL_PLANTER">
              {t("RICE_NO_TILL_PLANTER")}
            </option>
            <option value="MAIZE_NO_TILL_PLANTER">
              {t("MAIZE_NO_TILL_PLANTER")}
            </option>
            <option value="LAND_LEVELER">{t("LAND_LEVELER")}</option>
            <option value="SOIL_RIDGING_EQUIPMENT">
              {t("SOIL_RIDGING_EQUIPMENT")}
            </option>
            <option value="CASSAVA_ROOT_HARVESTER">
              {t("CASSAVA_ROOT_HARVESTER")}
            </option>
            <option value="DISC_PLOUGH_3">{t("DISC_PLOUGH_3")}</option>
            <option value="DISC_PLOUGH_6">{t("DISC_PLOUGH_6")}</option>
            <option value="ROTAVATOR_DRIED">{t("ROTAVATOR_DRIED")}</option>
            <option value="ROTAVATOR_WET">{t("ROTAVATOR_WET")}</option>
            <option value="COMBINE_HARVESTER">{t("COMBINE_HARVESTER")}</option>
            <option value="DRONE">{t("DRONE")}</option>
          </Select>
        </HStack>

        {!loading ? (
          filteredData.length > 0 ? (
            <SimpleGrid columns={columnCount} spacing="4">
              {filteredData.map((booking) => (
                <BookingDetailCard
                  booking={booking}
                  serviceType={serviceType}
                  key={booking.id}
                />
              ))}
            </SimpleGrid>
          ) : (
            <Flex
              width="full"
              height="full"
              align="center"
              justify="center"
              minHeight="200px"
            >
              <Text fontSize="xl" color="gray.500">
                {t(messageKey)}
              </Text>
            </Flex>
          )
        ) : (
          <Flex
            width="full"
            height="full"
            align="center"
            justify="center"
            minHeight="200px"
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Flex>
        )}
      </div>
    </MainLayout>
  );
};

export default ViewBooking;
