import React from "react";
import Navigationbar from "../Components/Navigationbar";
import Footer from "../Components/Footer";
import ImageSlider from "../Components/ImageSlider";
import BookNow from "./BookNow";
import "../Layout/Home.css";
import { Box, Image } from "@chakra-ui/react";

export default function Home() {
  return (
    <div className="parent">
      <Navigationbar></Navigationbar>
      <div className="container1">
        <div className="img-container">
          <ImageSlider />
          <BookNow></BookNow>
        </div>
        <Box position="fixed" bottom="12" right="5" zIndex="tooltip">
          <a href="https://t.me/+855975296777">
            <Image
              src="/telegram.png"
              alt="Descriptive Alt Text"
              w={{ md: "70px", base: "50px" }}
              h={{ md: "70px", base: "50px" }}
            />
          </a>
        </Box>
      </div>
      <Footer></Footer>
    </div>
  );
}
