import { useState, useEffect } from "react";
import MainLayout from "../Layout/MainLayout";
import "./CreateBookingPage.css";
import { useNavigate } from "react-router-dom";

import UserService from "../Components/Services/UserService";
import { useTranslation } from "react-i18next";
import BookingService from "../Components/Services/BookingService";
import PromoCodeService from "../Components/Services/PromoCodeService";
import { decodeToken } from "../Utilities/JsonDecode";
import { getToken, isTokenExpired } from "../Utilities/LocalStorage";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Button,
  Container,
  Box,
  Image,
  Flex,
  Spinner,
  HStack,
  FormLabel,
  FormControl,
  Input,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Center,
  useToast,
} from "@chakra-ui/react";
import GetAddress from "../CustomFunctions/geoCode";
import BadService from "../Components/Services/BadService";
import GoogleMapApi from "../Components/GoogleMapApi";
import { useJsApiLoader } from "@react-google-maps/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

const CreateBooking = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [serviceType] = useState("");
  const [eligibleAgent, setEligibleAgent] = useState([]);
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [discountCode, setDiscountCode] = useState("");
  const [proxySp, setProxySp] = useState([]);
  const [costPerHectare, setCostPerHectare] = useState(0);
  const [nextPaymentDays, setNextPaymentDays] = useState("0");
  const [disableButton, setDisableButton] = useState(false);
  const [bookservice, setbookservice] = useState({
    farmAddress: "",
    coordinates: { lat: 11.5642373, lng: 104.9111526 },
    preferredDate: Date.now() + 1,
    cropType: "",
    serviceType: "",
    soilType: "",
    numberOfHectare: 0,
    setPricing: 0,
    estimatedCost: 0,
    bookingConfirmed: false,
    paymentConfirmed: false,
    serviceCompleted: false,
    acceptanceID: "",
    preferredSP: {
      id: "",
      name: "",
      contact: "",
    },
    totalHour: 0,
    bookerDetails: {
      id: "",
      name: "",
      phoneNumber: "",
    },
    customerDetails: {
      id: "",
      name: "",
      phoneNumber: "",
      gender: "",
      dob: "",
    },
    discountDetails: {
      code: "",
      discountValueGiven: null,
    },
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const mutation = useMutation({
    mutationFn: async () => {
      const res = await UserService.get(
        `?phoneNumber=${bookservice.customerDetails.phoneNumber}`
      );
      return res.data.results[0];
    },
    onMutate: () => {
      // This runs before the mutation function
      setLoading(true);
    },
    onSuccess: (data) => {
      // Handle successful response
      // if (data.serviceType !== "farmer") {
      //   alert("User is not a farmer");
      //   return;
      // }
      setLoading(false);
      if (!data) {
        onOpen();
        return;
      }
      setbookservice((prevBookservice) => ({
        ...prevBookservice,
        customerDetails: {
          ...prevBookservice.customerDetails,
          id: data.id,
          phoneNumber: data.phoneNumber,
          name: data.name,
          gender: data.gender,
          dob: data.dob,
        },
      }));
      setCoordinates(data.coordinates);
      // You can update your state or perform other actions here
    },
    onError: (error) => {
      // Handle error
      setLoading(false);
      onOpen();
      setbookservice((prevBookservice) => ({
        ...prevBookservice,
        customerDetails: {
          ...prevBookservice.customerDetails,
          id: "",
          phoneNumber: "",
          name: "",
          gender: "",
          dob: "",
        },
      }));
      console.error("Error fetching user:", error);
    },
  });

  const handleSearchUserBasedOnPhoneNumber = () => {
    if (bookservice.customerDetails.phoneNumber.trim()) {
      mutation.mutate();
    }
  };

  const setCoordinates = async (coords) => {
    const address = await GetAddress(coords.lat, coords.lng);
    setbookservice((prevService) => ({
      ...prevService,
      farmAddress: address,
      coordinates: coords,
    }));
  };

  const formattedCost = `KHR ${bookservice.estimatedCost.toLocaleString(
    "en-US"
  )}`;

  const formatPrice = `KHR ${discountedPrice.toLocaleString("en-US")}`;

  // const offZones = {
  //   sangkea: {
  //     lat: 13.053074,
  //     lng: 103.374071,
  //     range: 10,
  //   },
  //   banan: {
  //     lat: 12.941785,
  //     lng: 103.144862,
  //     range: 10,
  //   },
  //   rotanak_mondol: {
  //     lat: 12.878688,
  //     lng: 102.85753,
  //     range: 10,
  //   },
  // };

  const buttonStyles = {
    colorScheme: "yellow",
    size: "md",
    borderRadius: "md",
    _hover: {
      bg: "yellow.600",
    },
    transition: "ease-in-out",
    transitionDuration: "150ms",
  };
  const [userLocation, setUserLocation] = useState({
    lat: 11.5642373,
    lng: 104.9111526,
  });

  const costRateConfig = {
    RICE_NO_TILL_PLANTER: {
      min: 140000,
      max: 160000,
    },
    MAIZE_NO_TILL_PLANTER: {
      min: 140000,
      max: 160000,
    },
    LAND_LEVELER: {
      min: 140000,
      max: 150000,
    },
    LASER_LAND_LEVELER: {
      min: 1700000,
      max: 180000,
    },
    SOIL_RIDGING_EQUIPMENT: {
      min: 180000,
      max: 220000,
    },
    CASSAVA_ROOT_HARVESTER: {
      min: 180000,
      max: 220000,
    },
    DISC_PLOUGH_6: {
      min: 120000,
      max: 160000,
    },
    DISC_PLOUGH_3: {
      min: 320000,
      max: 340000,
    },
    ROTAVATOR_DRIED: {
      min: 90000,
      max: 140000,
    },
    ROTAVATOR_WET: {
      min: 150000,
      max: 190000,
    },
    COMBINE_HARVESTER: {
      min: 400000,
      max: 480000,
    },
    DRONE: {
      min: 30000,
      max: 40000,
    },
  };

  const calculateMinDOB = () => {
    const today = new Date();
    const minDate = new Date(today.setFullYear(today.getFullYear() - 18));
    return minDate.toISOString().split("T")[0];
  };

  const setBooking = async (e) => {
    let { name, value } = e.target;
    if (name === "numberOfHectare") {
      value = parseFloat(value);
      setbookservice((bookservice) => ({ ...bookservice, [name]: value }));
    } else if (name === "totalHour") {
      value = parseFloat(value);
      setbookservice((bookservice) => ({
        ...bookservice,
        [name]: value,
      }));
    } else if (name === "serviceType") {
      // Call clearCode when serviceType changes
      clearCode();
      if (value === "") {
        setbookservice({
          ...bookservice,
          [name]: value,
        });
      } else {
        if (["LAND_LEVELER", "LASER_LAND_LEVELER"].includes(value)) {
          if (bookservice.totalHour !== 0) {
            setbookservice({
              ...bookservice,
              [name]: value,
              setPricing: costRateConfig[value].min,
            });
            // on service type changes, will query for proxy SP
            setLoading(true);
            const users = await UserService.get("/preferredSp", {
              params: {
                equipmentType: value,
                preferredDate: bookservice.preferredDate,
                serviceMeasure: bookservice.totalHour,
              },
            });
            if (users) {
              setProxySp(users.data);
              setLoading(false);
            }
            // setLoading(false);
          } else {
            setbookservice({
              ...bookservice,
              [name]: value,
              setPricing: costRateConfig[value].min,
            });
          }
        } else {
          if (bookservice.numberOfHectare !== 0) {
            setbookservice({
              ...bookservice,
              [name]: value,
              setPricing: costRateConfig[value].min,
            });
            // on service type changes, will query for proxy SP
            setLoading(true);
            const users = await UserService.get("/preferredSp", {
              params: {
                equipmentType: value,
                preferredDate: bookservice.preferredDate,
                serviceMeasure: bookservice.numberOfHectare,
              },
            });
            if (users) {
              setProxySp(users.data);
              setLoading(false);
            }
            // setLoading(false);
          } else {
            setbookservice({
              ...bookservice,
              [name]: value,
              setPricing: costRateConfig[value].min,
            });
          }
        }
      }

      if (value !== "") {
        setCostPerHectare(costRateConfig[value].min);
      } else {
        setCostPerHectare(0);
      }
    } else if (name === "preferredDate") {
      let preferredPayment = addDaysToDate(value, nextPaymentDays);
      setbookservice({
        ...bookservice,
        [name]: value,
        ["preferredPaymentDate"]: preferredPayment,
      });
    } else if (name === "nextPaymentDays") {
      setNextPaymentDays(value);
      let preferredPayment = addDaysToDate(bookservice.preferredDate, value);
      setbookservice({
        ...bookservice,
        ["preferredPaymentDate"]: preferredPayment,
      });
    } else if (name === "preferredSP") {
      const user = await UserService.get(`/${value}`);
      setbookservice({
        ...bookservice,
        [name]: {
          id: user.data ? value : "",
          name: user.data ? user.data.name : "",
          contact: user.data ? user.data.phoneNumber : "",
        },
      });
    } else if (name === "customerPhoneNumber") {
      setbookservice((prevBookservice) => ({
        ...prevBookservice,
        customerDetails: {
          ...prevBookservice.customerDetails,
          phoneNumber: value,
        },
      }));
    } else if (name === "name") {
      setbookservice((prevBookservice) => ({
        ...prevBookservice,
        customerDetails: {
          ...prevBookservice.customerDetails,
          name: value,
        },
      }));
    } else if (name === "customerGender") {
      setbookservice((prevBookservice) => ({
        ...prevBookservice,
        customerDetails: {
          ...prevBookservice.customerDetails,
          gender: value,
        },
      }));
    } else if (name === "customerDob") {
      const selectedDate = new Date(value);
      const minDate = new Date(calculateMinDOB());
      if (selectedDate > minDate) {
        alert(t("You must be at least 18 years old"));
      } else {
        setbookservice((prevBookservice) => ({
          ...prevBookservice,
          customerDetails: {
            ...prevBookservice.customerDetails,
            dob: value,
          },
        }));
      }
    } else {
      setbookservice({ ...bookservice, [name]: value });
    }
  };

  const clearCode = async () => {
    const { serviceType, numberOfHectare, totalHour } = bookservice;
    setDiscountCode("");
    setDiscountedPrice(0);
    if (serviceType === "LAND_LEVELER" && totalHour !== 0) {
      var estimatedCost = 100000 * totalHour;
      setbookservice({ ...bookservice, estimatedCost: estimatedCost });
    }
    if (serviceType === "LASER_LAND_LEVELER" && totalHour !== 0) {
      var estimatedCost = 170000 * totalHour;
      setbookservice({ ...bookservice, estimatedCost: estimatedCost });
    } else if (
      !["LAND_LEVELER", "LASER_LAND_LEVELER"].includes(bookservice.serviceType)
    ) {
      var estimatedCost = costPerHectare * numberOfHectare;
      setbookservice({
        ...bookservice,
        estimatedCost: estimatedCost,
        totalHour: 0,
      });
    }
  };

  const calculateEstimatedCost = async () => {
    const { serviceType, numberOfHectare, totalHour } = bookservice;

    if (serviceType) {
      // first if
      if (serviceType === "LAND_LEVELER" && totalHour !== 0) {
        if (discountCode !== "") {
          // check if code match or not
          const isCodeMatch = discountCode === eligibleAgent[0].promoCode.code;
          if (isCodeMatch) {
            const code = await getPromoCode(discountCode);
            if (code && code[0].limitValue > 0) {
              if (code[0].limitValue > numberOfHectare) {
                var total = 100000 * totalHour;
                var discount = code[0].discountValue;
                var pricing = total - discount;
                setDiscountedPrice(discount);
                if (pricing < 0) {
                  setbookservice({ ...bookservice, estimatedCost: 0 });
                } else {
                  setbookservice({ ...bookservice, estimatedCost: pricing });
                }
              } else {
                var total = 100000 * totalHour;
                var discount = code[0].discountValue;
                var pricing = total - discount;
                setDiscountedPrice(discount);
                if (pricing < 0) {
                  setbookservice({ ...bookservice, estimatedCost: 0 });
                } else {
                  setbookservice({ ...bookservice, estimatedCost: pricing });
                }
              }
            }
          } else {
            setDiscountedPrice(0);
            var estimatedCost = 100000 * totalHour;
            setbookservice({ ...bookservice, estimatedCost: estimatedCost });
          }
        } else {
          var estimatedCost = 100000 * totalHour;
          setbookservice({ ...bookservice, estimatedCost: estimatedCost });
        }
      }
      // second if
      if (serviceType === "LASER_LAND_LEVELER" && totalHour !== 0) {
        if (discountCode !== "") {
          // check if code match or not
          const isCodeMatch = discountCode === eligibleAgent[0].promoCode.code;
          if (isCodeMatch) {
            const code = await getPromoCode(discountCode);
            if (code[0] && code[0].limitValue > 0) {
              if (code[0].limitValue > numberOfHectare) {
                var total = 170000 * totalHour;
                var discount = code[0].discountValue;
                var pricing = total - discount;
                setDiscountedPrice(discount);
                if (pricing <= 0) {
                  setbookservice({ ...bookservice, estimatedCost: 0 });
                } else {
                  setbookservice({ ...bookservice, estimatedCost: pricing });
                }
              } else {
                var total = 170000 * totalHour;
                var discount = code[0].discountValue;
                var pricing = total - discount;
                setDiscountedPrice(discount);
                if (pricing <= 0) {
                  setbookservice({ ...bookservice, estimatedCost: 0 });
                } else {
                  setbookservice({ ...bookservice, estimatedCost: pricing });
                }
              }
            }
          } else {
            setDiscountedPrice(0);
            var estimatedCost = 170000 * totalHour;
            setbookservice({ ...bookservice, estimatedCost: estimatedCost });
          }
        } else {
          var estimatedCost = 170000 * totalHour;
          setbookservice({ ...bookservice, estimatedCost: estimatedCost });
        }
      }
      // third if
      else if (
        !["LAND_LEVELER", "LASER_LAND_LEVELER"].includes(
          bookservice.serviceType
        )
      ) {
        if (discountCode !== "") {
          // check if code match or not
          const isCodeMatch = discountCode === eligibleAgent[0].promoCode.code;
          if (isCodeMatch) {
            const code = await getPromoCode(discountCode);
            if (code && code[0].limitValue > 0) {
              if (
                code[0].limitValue >=
                numberOfHectare * code[0].discountValue
              ) {
                var total = costPerHectare * numberOfHectare;
                var discount = code[0].discountValue * numberOfHectare;
                var pricing = total - discount;
                setDiscountedPrice(discount);
                if (pricing <= 0) {
                  setbookservice({
                    ...bookservice,
                    estimatedCost: 0,
                    totalHour: 0,
                  });
                } else {
                  setbookservice({
                    ...bookservice,
                    estimatedCost: pricing,
                    totalHour: 0,
                  });
                }
              } else {
                var total = costPerHectare * numberOfHectare;
                var discountWithoutLimit =
                  code[0].discountValue * numberOfHectare;
                var discountAfterLimit =
                  code[0].discountValue * numberOfHectare - code[0].limitValue;
                var finalDiscount = discountWithoutLimit - discountAfterLimit;
                var pricing = total - finalDiscount;
                setDiscountedPrice(finalDiscount);
                if (pricing <= 0) {
                  setbookservice({
                    ...bookservice,
                    estimatedCost: 0,
                    totalHour: 0,
                  });
                } else {
                  setbookservice({
                    ...bookservice,
                    estimatedCost: pricing,
                    totalHour: 0,
                  });
                }
              }
            }
          } else {
            setDiscountedPrice(0);
            var estimatedCost = costPerHectare * numberOfHectare;
            setbookservice({
              ...bookservice,
              estimatedCost: estimatedCost,
              totalHour: 0,
            });
          }
        } else {
          var estimatedCost = costPerHectare * numberOfHectare;
          setbookservice({
            ...bookservice,
            estimatedCost: estimatedCost,
            totalHour: 0,
          });
        }
      }
    }
  };

  const handlePriceChange = async (value) => {
    setCostPerHectare(value);
    setbookservice({ ...bookservice, setPricing: value });
  };

  const getPromoCode = async (code) => {
    try {
      const res = await PromoCodeService.get(`/${code}`);
      return res.data;
    } catch (e) {
      console.error(e);
    }
  };

  const getEligibleAgents = async () => {
    try {
      const agents = await BadService.get(`/${userId}`);
      if (agents) {
        setEligibleAgent(agents.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  // const handleApplyCode =

  const getdata = async (e) => {
    e.preventDefault();
    // Basic form validation
    if (
      !bookservice.farmAddress ||
      !bookservice.preferredDate ||
      !bookservice.cropType ||
      !bookservice.serviceType ||
      !bookservice.soilType ||
      !bookservice.numberOfHectare ||
      !bookservice.estimatedCost ||
      (bookservice.serviceType === "LAND_LEVELER" && !bookservice.totalHour) ||
      (bookservice.serviceType === "LASER_LAND_LEVELER" &&
        !bookservice.totalHour)
    ) {
      alert(`${t("missing-info")}`);
      return;
    }
    try {
      setDisableButton(true);
      await BookingService.post("", bookservice);

      // invalidate the bookings query
      queryClient.invalidateQueries({
        queryKey: ["bookings"],
        exact: false,
      });

      if (discountCode !== "") {
        const res = await getPromoCode(discountCode);
        if (res) {
          await PromoCodeService.post(`/${discountCode}`, {
            numberOfHectare: bookservice.numberOfHectare,
          });
        }
      }

      // send push notification
      const formData = {
        userId: bookservice.preferredSP.id,
        title: "Booking Requested!",
        body: `${bookservice.bookerDetails.name} book your service!`,
        url: "/viewBooking",
      };
      await notificationMutation.mutateAsync(formData);

      alert(`${t("booking-created")}`);
      navigate("/viewBooking");
    } catch (error) {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.error("Error sending request:", error);
      setDisableButton(false);
    }
  };

  const notificationMutation = useMutation({
    mutationFn: (formData) => {
      return axios.post(
        process.env.REACT_APP_API_END_POINT +
          "/v1/notification/send-notification",
        formData
      );
    },
  });

  // function haversine(lat1, lon1, lat2, lon2) {
  //   // Radius of the Earth in kilometers
  //   const R = 6371;
  //   // Convert degrees to radians
  //   const radLat1 = (lat1 * Math.PI) / 180;
  //   const radLon1 = (lon1 * Math.PI) / 180;
  //   const radLat2 = (lat2 * Math.PI) / 180;
  //   const radLon2 = (lon2 * Math.PI) / 180;
  //   // Difference in coordinates
  //   const dLat = radLat2 - radLat1;
  //   const dLon = radLon2 - radLon1;
  //   // Haversine formula
  //   const a =
  //     Math.sin(dLat / 2) * Math.sin(dLat / 2) +
  //     Math.cos(radLat1) *
  //       Math.cos(radLat2) *
  //       Math.sin(dLon / 2) *
  //       Math.sin(dLon / 2);
  //   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  //   const distance = R * c;
  //   return distance;
  // }

  // function isUserOutsideRanges(userLat, userLng, locations) {
  //   for (let key in locations) {
  //     if (locations.hasOwnProperty(key)) {
  //       const location = locations[key];
  //       const distance = haversine(
  //         userLat,
  //         userLng,
  //         location.lat,
  //         location.lng
  //       );
  //       if (distance <= location.range) {
  //         return false; // The user is within range of this location
  //       }
  //     }
  //   }
  //   return true; // The user is outside the range of all given locations
  // }

  function addDaysToDate(dateStr, daysStr) {
    // Step 1: Create a date object from the input string
    let date = new Date(dateStr);

    // Step 2: Convert the days string to an integer
    let days = parseInt(daysStr, 10);

    // Step 3: Add the days to the date
    date.setDate(date.getDate() + days);

    // Step 4: Format the date back to YYYY-MM-DD
    let newDate = date.toISOString().split("T")[0];

    return newDate;
  }

  const fetchUserData = async () => {
    const token = getToken();
    const userId = decodeToken(token);
    const user = await UserService.get(`/${userId}`);
    setUserId(userId);
    if (user) {
      const userServiceType = user.data.serviceType;

      // setServiceType(userServiceType);
      // setUserLocation({
      //   lat: user.data.coordinates.lat,
      //   lng: user.data.coordinates.lng,
      // });
      // const address = await GetAddress(
      //   user.data.coordinates.lat,
      //   user.data.coordinates.lng
      // );

      setbookservice((bookservice) => ({
        ...bookservice,
        bookerDetails: {
          ...bookservice.bookerDetails,
          id: userId,
          phoneNumber: user.data.phoneNumber,
          name: user.data.name,
        },
      }));

      if (userServiceType === "serviceProvider") {
        navigate("/viewBooking");
      } else if (userServiceType === "farmer") {
        navigate("/farmerBooking");
      } else {
        navigate("/createBooking");
      }
      fetchData();
    } else {
      console.log("Unauthorized");
      setLoading(false);
    }
  };

  const getUserLocation = async () => {
    // if geolocation is supported by the users browser
    if (navigator.geolocation) {
      // get the current users location
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          // save the geolocation coordinates in two variables
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;

          // const off = isUserOutsideRanges(lat, lng, offZones);
          // if (!off) {
          //   navigate("/waitlist");
          // } else {
          // update the value of userlocation variable
          setUserLocation({ lat, lng });
          setbookservice((bookservice) => ({
            ...bookservice,
            ["coordinates"]: { lat: lat, lng: lng },
          }));
          const address = await GetAddress(lat, lng);
          setbookservice((bookservice) => ({
            ...bookservice,
            ["farmAddress"]: address,
          }));
          // }
        },
        // if there was an error getting the users location
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    }
    // if geolocation is not supported by the users browser
    else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const checkToken = async () => {
    const expired = isTokenExpired();
    if (expired === true) {
      navigate("/login");
    } else {
      fetchUserData();
      getUserLocation();
    }
  };

  const populateDiscountCode = async () => {
    setbookservice((bookservice) => ({
      ...bookservice,
      discountDetails: {
        ...bookservice.discountDetails,
        code: discountCode,
        discountValueGiven: discountedPrice,
      },
    }));
  };

  useEffect(() => {
    populateDiscountCode();
  }, [discountCode, discountedPrice]);

  useEffect(() => {
    checkToken();
  }, []);

  useEffect(() => {
    getEligibleAgents();
  }, [userId]);

  useEffect(() => {
    const fetchProxySp = async () => {
      // Check if serviceType and coordinates are not empty or undefined
      if (
        bookservice.serviceType !== "" &&
        bookservice.preferredDate !== "" &&
        (["LAND_LEVELER", "LASER_LAND_LEVELER"].includes(
          bookservice.serviceType
        )
          ? bookservice.totalHour !== 0
          : bookservice.numberOfHectare !== 0)
      ) {
        try {
          setLoading(true);
          const response = await UserService.get("/preferredSp", {
            params: {
              equipmentType: bookservice.serviceType,
              preferredDate: bookservice.preferredDate,
              serviceMeasure: ["LAND_LEVELER", "LASER_LAND_LEVELER"].includes(
                bookservice.serviceType
              )
                ? bookservice.totalHour
                : bookservice.numberOfHectare,
            },
          });
          // Assuming the response data is the array of proxy service providers
          if (response && response.data) {
            if (isLoaded && bookservice.coordinates && response.data) {
              calculateRoadDistance(bookservice.coordinates, response.data)
                .then((distances) => {
                  const sortedDistances = distances.sort(
                    (a, b) => a.distance - b.distance
                  );
                  setProxySp(sortedDistances);
                })
                .catch((error) => console.error(error));
            }
            setLoading(false);
          }
        } catch (error) {
          console.error("Failed to fetch proxy service providers:", error);
        }
      }
    };

    fetchProxySp();
    getEligibleAgents();
  }, [
    bookservice.coordinates,
    bookservice.serviceType,
    bookservice.preferredDate,
    ["LAND_LEVELER", "LASER_LAND_LEVELER"].includes(bookservice.serviceType)
      ? bookservice.totalHour
      : bookservice.numberOfHectare,
  ]);

  // google map api for calculate road distance
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOLE_MAP_API,
    libraries: ["places"],
  });

  const calculateRoadDistance = (
    selectedCoordinate,
    serviceProviderCoordinates
  ) => {
    if (!isLoaded) return;

    return new Promise((resolve, reject) => {
      const service = new window.google.maps.DistanceMatrixService();

      service.getDistanceMatrix(
        {
          origins: [
            new window.google.maps.LatLng(
              selectedCoordinate.lat,
              selectedCoordinate.lng
            ),
          ],
          destinations: serviceProviderCoordinates.map(
            (coord) =>
              new window.google.maps.LatLng(
                coord.coordinates.lat,
                coord.coordinates.lng
              )
          ),
          travelMode: "DRIVING",
        },
        (response, status) => {
          if (status === "OK") {
            const distances = serviceProviderCoordinates.map(
              (coord, index) => ({
                ...coord,
                distance: (
                  response.rows[0].elements[index].distance.value / 1000
                ).toFixed(2), // Convert meters to km
              })
            );
            resolve(distances);
          } else {
            reject(new Error(`Distance Matrix failed due to ${status}`));
          }
        }
      );
    });
  };

  const fetchData = async () => {
    // Replace this with your actual logic to fetch data based on service type
    // console.log("Fetching data based on service type:", serviceType);
  };

  // Check if the user is eligible to use the promo code based on the conditions
  const isUserEligible = eligibleAgent.some(
    (agent) =>
      agent.baID === userId &&
      agent.promoCode &&
      agent.promoCode.limitValue &&
      Array.isArray(agent.promoCode.serviceTypes) &&
      agent.promoCode.serviceTypes.includes(bookservice.serviceType)
  );

  return (
    <MainLayout>
      {loading && (
        <Flex
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          width="100vw"
          height="100vh"
          align="center"
          justify="center"
          backgroundColor="rgba(255, 255, 255, 0.5)" // Semi-transparent white
          zIndex="modal" // Ensures it's above everything else
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Flex>
      )}
      <Container>
        <h2>{t("make-booking")}</h2>
        {/* <h2>Create your bookings here</h2> */}
        <div className="CreateBookingPage">
          <div className="BookingContainer">
            <form onSubmit={getdata}>
              {/* cus phone number */}
              <FormControl isRequired>
                <FormLabel htmlFor="customerPhoneNumber">
                  {t("phone-number")}
                </FormLabel>
                <Input
                  type="number"
                  id="customerPhoneNumber"
                  name="customerPhoneNumber"
                  value={bookservice.customerDetails.phoneNumber}
                  autoComplete="off"
                  placeholder={t("enter-phone-number")}
                  onChange={setBooking}
                />
                <Flex justifyContent="space-between">
                  <Box>
                    <Button
                      size="md"
                      colorScheme="blue"
                      isLoading={mutation.isLoading}
                      onClick={() => {
                        navigate("/registerNewCustomer");
                      }}
                    >
                      {t("add-farmer")}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      size="md"
                      colorScheme="yellow"
                      onClick={handleSearchUserBasedOnPhoneNumber}
                      isLoading={mutation.isLoading}
                      loadingText="Searching"
                      isDisabled={
                        !bookservice.customerDetails.phoneNumber.trim()
                      }
                    >
                      {t("find-farmer")}
                    </Button>
                  </Box>
                </Flex>
              </FormControl>

              {/* cus full name */}
              <label htmlFor="name">{t("full-name")}</label>
              <input
                type="text"
                id="name"
                name="name"
                value={bookservice.customerDetails.name}
                autoComplete="off"
                placeholder={t("full-name")}
                required
                disabled
                onChange={setBooking}
              />
              {/* cus gender */}
              {/* <div className="dropdown">
                <label htmlFor="customerGender">{t("gender")}</label>
                <select
                  id="customerGender"
                  name="customerGender"
                  value={bookservice.customerDetails.gender}
                  required
                  onChange={setBooking}
                >
                  <option value={""}></option>
                  <option value={"male"}>{t("male")}</option>
                  <option value={"female"}>{t("female")}</option>
                  <option value={"other"}>{t("noShow")}</option>
                  <option value={"other"}>{t("other")}</option>
                </select>
              </div> */}
              {/* cus dob */}
              {/* <label htmlFor="customerDob">{t("dob")}</label>
              <input
                type="date"
                id="customerDob"
                name="customerDob"
                value={bookservice.customerDetails.dob}
                required
                max={calculateMinDOB()}
                onChange={setBooking}
                lang="km-KH"
              /> */}
              {/* farm address */}
              <label htmlFor="farmAddress">{t("address")}</label>
              <GoogleMapApi
                coordinates={bookservice.coordinates}
                setCoordinates={setCoordinates}
                farmAddress={bookservice.farmAddress}
              />
              {/* preferred date */}
              <label htmlFor="preferredDate">{t("request-date")}</label>
              <input
                type="date"
                id="preferredDate"
                name="preferredDate"
                value={bookservice.preferredDate}
                required
                min={(() => {
                  const today = new Date();
                  return today.toISOString().split("T")[0];
                })()}
                max={(() => {
                  const today = new Date();
                  const futureDate = new Date(today);
                  futureDate.setDate(futureDate.getDate() + 5);
                  return futureDate.toISOString().split("T")[0];
                })()}
                onChange={setBooking}
                lang="km-KH"
              />
              {/* crop type */}
              <div className="dropdown">
                <label htmlFor="cropType">{t("crop-type")}</label>
                <select
                  id="cropType"
                  name="cropType"
                  onChange={setBooking}
                  value={bookservice.cropType}
                  // required onChange={setBooking}
                >
                  <option value=""></option>
                  <option value="rice">{t("rice")}</option>
                  <option value="maize">{t("maize")}</option>
                  <option value="potato">{t("potato")}</option>
                  <option value="bean">{t("bean")}</option>
                  <option value="coverCrop">{t("coverCrop")}</option>
                </select>
              </div>
              {/* service type */}
              <div className="dropdown">
                <label htmlFor="serviceType">{t("service-type")}</label>
                <select
                  id="serviceType"
                  name="serviceType"
                  required
                  onChange={setBooking}
                  onBlur={calculateEstimatedCost}
                >
                  <option value=""></option>
                  <option value="RICE_NO_TILL_PLANTER">
                    {t("RICE_NO_TILL_PLANTER")}
                  </option>
                  <option value="MAIZE_NO_TILL_PLANTER">
                    {t("MAIZE_NO_TILL_PLANTER")}
                  </option>
                  <option value="LAND_LEVELER">{t("LAND_LEVELER")}</option>
                  <option value="LASER_LAND_LEVELER">
                    {t("LASER_LAND_LEVELER")}
                  </option>
                  <option value="SOIL_RIDGING_EQUIPMENT">
                    {t("SOIL_RIDGING_EQUIPMENT")}
                  </option>
                  <option value="CASSAVA_ROOT_HARVESTER">
                    {t("CASSAVA_ROOT_HARVESTER")}
                  </option>
                  <option value="DISC_PLOUGH_3">{t("DISC_PLOUGH_3")}</option>
                  <option value="DISC_PLOUGH_6">{t("DISC_PLOUGH_6")}</option>
                  <option value="ROTAVATOR_DRIED">
                    {t("ROTAVATOR_DRIED")}
                  </option>
                  <option value="ROTAVATOR_WET">{t("ROTAVATOR_WET")}</option>
                  <option value="COMBINE_HARVESTER">
                    {t("COMBINE_HARVESTER")}
                  </option>
                  <option value="DRONE">{t("DRONE")}</option>
                </select>
              </div>
              {!["LAND_LEVELER", "LASER_LAND_LEVELER"].includes(
                bookservice.serviceType
              ) && (
                <div className="m-3">
                  <p>{costPerHectare} KHR</p>
                  <Slider
                    value={costPerHectare}
                    min={
                      bookservice.serviceType !== ""
                        ? costRateConfig[bookservice.serviceType].min
                        : 0
                    }
                    max={
                      bookservice.serviceType !== ""
                        ? costRateConfig[bookservice.serviceType].max
                        : 0
                    }
                    step={bookservice.serviceType === "DRONE" ? 5000 : 10000}
                    onChange={async (value) => {
                      await handlePriceChange(value);
                    }}
                    onChangeEnd={calculateEstimatedCost}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb boxSize={6} bgColor={"yellow.400"} />
                  </Slider>
                </div>
              )}
              {["LAND_LEVELER", "LASER_LAND_LEVELER"].includes(
                bookservice.serviceType
              ) && (
                <>
                  <label>{t("land-leveler-pricing")}</label>
                  <label htmlFor="totalHour">{t("hour-usage")}</label>
                  <input
                    type="number"
                    step=".1"
                    id="totalHour"
                    name="totalHour"
                    value={bookservice.totalHour}
                    autoComplete="off"
                    placeholder={t("hour-usage")}
                    min={1.0}
                    max={50.0}
                    required
                    onChange={setBooking}
                    onKeyUp={calculateEstimatedCost}
                  />
                </>
              )}
              {/* {bookservice.serviceType === "LASER_LAND_LEVELER" && (
                <>
                  <label>{t("laser-land-leveler-pricing")}</label>
                  <label htmlFor="totalHour">{t("hour-usage")}</label>
                  <input
                    type="number"
                    id="totalHour"
                    name="totalHour"
                    value={bookservice.totalHour}
                    autoComplete="off"
                    placeholder={t("hour-usage")}
                    required
                    onChange={setBooking}
                    onBlur={calculateEstimatedCost}
                  />
                </>
              )} */}
              {/* land type */}
              <div className="dropdown">
                <label htmlFor="soilType">{t("land-type")}</label>
                <select
                  id="soilType"
                  name="soilType"
                  value={bookservice.soilType}
                  required
                  onChange={setBooking}
                >
                  <option value=""></option>
                  <option value="bsc">{t("bsc")}</option>
                  <option value="nfs">{t("nfs")}</option>
                  <option value="sand">{t("sand")}</option>
                  <option value="gravel">{t("gravel")}</option>
                </select>
              </div>
              {/* number of hectare */}
              <label htmlFor="numberOfHectare">{t("number-hectare")}</label>
              <input
                type="number"
                step=".1"
                id="numberOfHectare"
                name="numberOfHectare"
                value={bookservice.numberOfHectare}
                placeholder={t("number-hectare")}
                autoComplete="off"
                required
                min={0.1}
                onChange={setBooking}
                onKeyUp={calculateEstimatedCost}
              />
              {/* preferred sp */}
              <div className="dropdown">
                <label htmlFor="preferredSP">{t("select-sp")}</label>
                <select
                  id="preferredSP"
                  name="preferredSP"
                  value={bookservice.preferredSP.id}
                  onChange={setBooking}
                  required
                >
                  <option value=""></option>
                  {proxySp.length > 0 &&
                    proxySp.map((sp) => (
                      <option key={sp.id} value={sp.id}>
                        {sp.name} - {sp.distance} km
                      </option>
                    ))}
                </select>
              </div>
              {/* estimated cost */}
              <label htmlFor="estimatedCost">{t("estimate-cost")} (KHR)</label>
              <input
                type="text"
                id="estimatedCost"
                name="estimatedCost"
                value={formattedCost}
                placeholder="តម្លៃប៉ាន់ប្រមាណ"
                // placeholder="Estimated Cost"
                autoComplete="off"
                disabled
              />
              {/* discount code */}
              {isUserEligible && (
                <>
                  <label htmlFor="discountCode">{t("discount-code")}</label>
                  <input
                    type="text"
                    id="discountCode"
                    name="discountCode"
                    value={discountCode}
                    placeholder={t("discount-code")}
                    autoComplete="off"
                    onChange={(e) => {
                      setDiscountCode(e.target.value);
                    }}
                  />
                  <label>
                    {t("discount-price")} {formatPrice}
                  </label>
                  <HStack justifyContent="space-between">
                    <Button
                      onClick={calculateEstimatedCost}
                      isDisabled={discountCode === ""}
                      width="40%"
                    >
                      {t("apply-code")}
                    </Button>
                    <Button
                      onClick={clearCode}
                      isDisabled={discountCode === ""}
                      width="40%"
                    >
                      {t("clear-code")}
                    </Button>
                  </HStack>
                </>
              )}
              {/* TODO: remove payment date for now */}
              {/* payment date to farm hero */}
              {/* <div className="dropdown">
                <label htmlFor="nextPaymentDays">
                  {t("payment-date-to-farm-hero")}
                </label>
                <select
                  id="nextPaymentDays"
                  name="nextPaymentDays"
                  value={nextPaymentDays}
                  required
                  onChange={setBooking}
                >
                  <option value="0">
                    {t("today")} (
                    {addDaysToDate(bookservice.preferredDate, "0")})
                  </option>
                  <option value="7">
                    {t("one-week")} (
                    {addDaysToDate(bookservice.preferredDate, "7")})
                  </option>
                  <option value="14">
                    {t("two-weeks")} (
                    {addDaysToDate(bookservice.preferredDate, "14")})
                  </option>
                </select>
              </div> */}
              <Button
                {...buttonStyles}
                type="submit"
                isDisabled={disableButton}
                onClick={() => setbookservice({ ...bookservice })}
              >
                {t("request")}
              </Button>
            </form>
          </div>
        </div>
      </Container>
      <Box position="fixed" bottom="5" right="5" zIndex="tooltip">
        <a href="https://t.me/+855975296777">
          <Image
            src="/telegram.png"
            alt="Descriptive Alt Text"
            w={{ md: "70px", base: "50px" }}
            h={{ md: "70px", base: "50px" }}
          />
        </a>
      </Box>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent margin="25px">
          <ModalHeader>
            <Center>{t("farmer-not-found")}</Center>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center fontSize={"medium"} fontWeight={"semi-bold"}>
              {t("farmer-not-found-desc")}
            </Center>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="space-between" width="100%" marginX={5}>
              <Box>
                <Button colorScheme="teal" onClick={onClose}>
                  {t("go-back")}
                </Button>
              </Box>

              <Box>
                <Button
                  colorScheme="yellow"
                  onClick={() => {
                    navigate("/registerNewCustomer");
                  }}
                >
                  {t("add-farmer")}
                </Button>
              </Box>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </MainLayout>
  );
};

export default CreateBooking;
