import { Link as RouterLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import React, { useState, useEffect } from "react";
import "./Login.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import GetAddress from "../CustomFunctions/geoCode";
import {
  Flex,
  Center,
  Box,
  Image,
  HStack,
  VStack,
  Radio,
  RadioGroup,
  Button,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Select,
  Text,
  InputGroup,
  InputLeftAddon,
  useBreakpointValue,
  Checkbox,
} from "@chakra-ui/react";
import DropdownSelectWithTags from "../Components/DropdownSelectWithTags";
import { i18n } from "../i18n/config";
import RegisterGoogleMaps from "./RegisterGoogleMap";

function Register() {
  // const history = useNavigate();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState([]);
  const [serviceType, setServiceType] = useState("farmer");
  const [agreeCommission, setAgreeCommission] = useState(false);
  const [language, setLanguage] = useState(i18n.language);
  const [formData, setFormData] = useState({
    farmAddress: "",
    coordinates: {
      lat: 0,
      lng: 0,
    },
    serviceType: "",
    serviceProviderOptions: [],
    gender: "",
    phoneNumber: "",
    name: "",
    dob: "",
    agreeCommission: false,
  });
  const [show, setShow] = React.useState(false);
  const handleShowPassword = () => setShow(!show);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      phoneNumber: "",
      password: "",
      serviceType: "",
      gender: "",
      farmAddress: "",
      dob: "",
      coordinates: {},
      serviceProviderOptions: [],
      agreeCommission: false,
    },
  });

  const buttonStyles = {
    colorScheme: "yellow",
    size: "md",
    borderRadius: "md",
    _hover: {
      bg: "yellow.600",
    },
    transition: "ease-in-out",
    transitionDuration: "150ms",
  };
  const [userLocation, setUserLocation] = useState({
    lat: 11.5642373,
    lng: 104.9111526,
  });

  const offZones = {
    // sangkea: {
    //   lat: 13.053074,
    //   lng: 103.374071,
    //   range: 50,
    // },
    // banan: {
    //   lat: 12.941785,
    //   lng: 103.144862,
    //   range: 50,
    // },
    // rotanak_mondol: {
    //   lat: 12.878688,
    //   lng: 102.85753,
    //   range: 50,
    // },
    vikram_house: {
      lat: 12.093095,
      lng: 108.860301,
      range: 10,
    },
  };

  async function onSubmit(values) {
    const registerData = {
      ...formData,
      ...values,
    };
    registerData.serviceProviderOptions = selectedItems;
    registerData.serviceType = serviceType;
    console.log(registerData);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_END_POINT + "/v1/auth/register",
        registerData,
      );
      if (response.status === 201) {
        navigate("/login");
      }
    } catch (error) {
      switch (error.response.data.message) {
        case '"lat" is required, "lng" is required':
          alert(t("please-recenter-map"));
          break;
        case '"Phone Number already taken"':
          alert(t("taken-number"));
          break;
        default:
          alert(error.response.data.message);
          break;
      }
    }
  }

  const setLocation = async (location) => {
    setUserLocation({ lat: location.lat, lng: location.lng });
    const address = await GetAddress(location.lat, location.lng);
    setValue("farmAddress", address);
    setValue("coordinates", { lat: location.lat, lng: location.lng });
  };

  const handleToggleView = () => {
    navigate("/login");
  };

  function haversine(lat1, lon1, lat2, lon2) {
    // Radius of the Earth in kilometers
    const R = 6371;
    // Convert degrees to radians
    const radLat1 = (lat1 * Math.PI) / 180;
    const radLon1 = (lon1 * Math.PI) / 180;
    const radLat2 = (lat2 * Math.PI) / 180;
    const radLon2 = (lon2 * Math.PI) / 180;
    // Difference in coordinates
    const dLat = radLat2 - radLat1;
    const dLon = radLon2 - radLon1;
    // Haversine formula
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(radLat1) *
        Math.cos(radLat2) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  }

  function isUserOutsideRanges(userLat, userLng, locations) {
    for (let key in locations) {
      if (locations.hasOwnProperty(key)) {
        const location = locations[key];
        const distance = haversine(
          userLat,
          userLng,
          location.lat,
          location.lng,
        );
        if (distance <= location.range) {
          return false; // The user is within range of this location
        }
      }
    }
    return true; // The user is outside the range of all given locations
  }

  const getUserLocation = async () => {
    // if geolocation is supported by the users browser
    if (navigator.geolocation) {
      // get the current users location
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          // save the geolocation coordinates in two variables
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;

          const off = isUserOutsideRanges(lat, lng, offZones);
          if (!off) {
            navigate("/waitlist");
          } else {
            // update the value of userlocation variable
            setUserLocation({ lat, lng });
            setValue("coordinates", { lat: lat, lng: lng });
            setFormData({
              ...formData,
              ["coordinates"]: { lat: lat, lng: lng },
            });
            const address = await GetAddress(lat, lng);
            setValue("farmAddress", address);
            setFormData({
              ...formData,
              ["farmAddress"]: address,
            });
          }
        },
        // if there was an error getting the users location
        (error) => {
          console.error("Error getting user location:", error);
        },
      );
    }
    // if geolocation is not supported by the users browser
    else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleMarkerPositionChange = async (newPosition) => {
    setValue("coordinates", { lat: newPosition.lat, lng: newPosition.lng });
    setFormData({
      ...formData,
      ["coordinates"]: { lat: newPosition.lat, lng: newPosition.lng },
    });
    const address = await GetAddress(newPosition.lat, newPosition.lng);
    setValue("farmAddress", address);
    setFormData({
      ...formData,
      ["farmAddress"]: address,
    });
  };

  const handleChangeLanguage = () => {
    if (language === "km") {
      i18n.changeLanguage("en");
      setLanguage("en");
    } else {
      i18n.changeLanguage("km");
      setLanguage("km");
    }
  };

  const margin = useBreakpointValue({ base: "3", md: "3" });

  useEffect(() => {
    getUserLocation();
  }, [navigate]);

  return (
    <Flex direction={{ base: "column", md: "row" }} w="100vw" h="100vh" gap={3}>
      <Center w={{ base: "full", md: "50%" }} mr={margin}>
        <Flex
          direction={"column"}
          gap={{ base: 3, md: 0 }}
          w={{ base: "full", md: "90%" }}
          m={2}
        >
          <Flex direction={"row"} justify={"space-between"}>
            <RouterLink to="/">
              <img
                src="/FarmHero__Logomark_Gold.png"
                width="90"
                height="60"
                alt=""
              />
            </RouterLink>
            <Box zIndex="tooltip">
              <Image
                src={language === "km" ? "/cambodia.png" : "/uk.png"}
                alt="Descriptive Alt Text"
                w="50px"
                h="30px"
                onClick={handleChangeLanguage}
              />
            </Box>
          </Flex>
          <Flex
            direction={{ base: "column", md: "row" }}
            gap={5}
            w="full"
            justify={"space-evenly"}
          >
            <VStack w={"full"}>
              <FormControl isInvalid={errors.farmAddress} isRequired>
                <FormLabel htmlFor="farmAddress">{t("address")}</FormLabel>
                <Input
                  id="farmAddress"
                  placeholder={t("address")}
                  {...register("farmAddress", {
                    required: i18n.t("required"),
                  })}
                />
                <FormErrorMessage>
                  {errors.farmAddress && errors.farmAddress.message}
                </FormErrorMessage>
              </FormControl>

              <RegisterGoogleMaps
                center={userLocation}
                setUserLocation={handleMarkerPositionChange}
                onUserLocationChange={setLocation}
              />
              <br></br>
              <br></br>
              <Text fontSize="md" fontWeight={"bold"}>
                ** {t("please-recenter-map")}
              </Text>
              <br></br>
              <FormControl isRequired>
                <FormLabel>{t("gender")}</FormLabel>
                <Select
                  // placeholder="Select Gender"
                  id="gender"
                  {...register("gender", {
                    required: i18n.t("required"),
                    validate: {
                      emptyGender: (value) => {
                        if (value === "") {
                          return i18n.t("required");
                        }
                      },
                    },
                  })}
                >
                  <option value={""}></option>
                  <option value={"male"}>{t("male")}</option>
                  <option value={"female"}>{t("female")}</option>
                  <option value={"other"}>{t("noShow")}</option>
                  <option value={"other"}>{t("other")}</option>
                </Select>
              </FormControl>
            </VStack>

            <VStack w={"full"}>
              <FormControl isInvalid={errors.name} isRequired>
                <FormLabel htmlFor="name">{t("full-name")}</FormLabel>
                <Input
                  id="name"
                  placeholder={t("full-name")}
                  {...register("name", {
                    required: i18n.t("required"),
                  })}
                />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.dob} isRequired>
                <FormLabel htmlFor="dob">{t("dob")}</FormLabel>
                <Input
                  id="dob"
                  type="date"
                  {...register("dob", {
                    required: i18n.t("required"),
                    validate: {
                      is18OrOlder: (value) => {
                        const today = new Date();
                        const birthDate = new Date(value);
                        const cutoffDate = new Date(
                          today.getFullYear() - 18,
                          today.getMonth(),
                          today.getDate(),
                        );

                        // Check if the birthDate is before the cutoffDate
                        return birthDate <= cutoffDate || i18n.t("least-18");
                      },
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.dob && errors.dob.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.phoneNumber} isRequired>
                <FormLabel htmlFor="phoneNumber">{t("phone-number")}</FormLabel>
                <InputGroup>
                  <InputLeftAddon>+855</InputLeftAddon>
                  <Input
                    type="tel"
                    placeholder="phone number"
                    {...register("phoneNumber", {
                      required: i18n.t("required"),
                      validate: {
                        validatePhoneNumber: (value) => {
                          // Check if the value only consists of digits
                          const isNumeric = /^\d+$/.test(value);
                          if (
                            !isNumeric ||
                            value.length < 9 ||
                            value.length > 10
                          ) {
                            return i18n.t("phone-valid");
                          }
                          return true;
                        },
                      },
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>
                  {errors.phoneNumber && errors.phoneNumber.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.password} isRequired>
                <FormLabel htmlFor="password">{t("password")}</FormLabel>
                <Input
                  id="password"
                  type={show ? "text" : "password"}
                  placeholder={t("password")}
                  {...register("password", {
                    required: i18n.t("required"),
                    validate: {
                      validatePassword: (value) => {
                        if (value.length < 8) {
                          return i18n.t("pass-8char");
                        }
                        if (!value.match(/\d/) || !value.match(/[a-zA-Z]/)) {
                          return i18n.t("pass-invalid");
                        }
                        return true; // return true if the value is valid
                      },
                    },
                  })}
                />
                <p className="password-instruction">
                  {/* Password must be at least 8 characters long. */}
                  {t("passwordValid")}
                </p>
                <Button
                  {...buttonStyles}
                  h="2.5rem"
                  w="30%"
                  size="sm"
                  onClick={handleShowPassword}
                >
                  {show ? `${t("hide")}` : `${t("show")}`}
                </Button>
                <FormErrorMessage>
                  {errors.password && errors.password.message}
                </FormErrorMessage>
              </FormControl>

              <RadioGroup defaultValue={"farmer"}>
                <Text>{t("i-am")}</Text>
                <HStack>
                  <Radio
                    {...register("serviceType")}
                    value={"farmer"}
                    size="lg"
                    onChange={() => {
                      setServiceType("farmer");
                      setSelectedItems([]);
                    }}
                  >
                    <Text>{t("farmer")}</Text>
                  </Radio>
                  <Radio
                    {...register("serviceType")}
                    value={"serviceProvider"}
                    size="lg"
                    onChange={() => {
                      setServiceType("serviceProvider");
                    }}
                  >
                    <Text>{t("serviceProvider")}</Text>
                  </Radio>
                </HStack>
              </RadioGroup>

              {serviceType === "serviceProvider" && (
                <>
                  <DropdownSelectWithTags
                    selectedItemsProp={selectedItems}
                    onSelectionChange={setSelectedItems}
                  />
                  <Checkbox
                    onChange={(e) => {
                      setValue("agreeCommission", e.target.checked);
                      setAgreeCommission(e.target.checked);
                    }}
                  >
                    {t("commission")}
                  </Checkbox>
                </>
              )}
              <Button
                {...buttonStyles}
                onClick={handleSubmit(onSubmit)}
                isDisabled={
                  serviceType === "serviceProvider" &&
                  (!agreeCommission || selectedItems.length === 0)
                }
              >
                {t("register")}
              </Button>
              <div className="bottom-section bottomSectionContainer">
                <p>{t("existingUser")}</p>
                <a
                  className="Apple"
                  href="#register"
                  onClick={handleToggleView}
                >
                  {t("login")}
                </a>
              </div>
            </VStack>
          </Flex>{" "}
        </Flex>
      </Center>

      <Box
        w={{ base: "full", md: "50%" }}
        display={{ base: "none", md: "block" }}
      >
        <Image src="../Farmers.jpg" w={"full"} h={"full"} objectFit={"cover"} />
      </Box>

      <Box position="fixed" bottom="5" right="5" zIndex="tooltip">
        <a href="https://t.me/+855975296777">
          <Image
            src="/telegram.png"
            alt="Descriptive Alt Text"
            w={{ md: "70px", base: "50px" }}
            h={{ md: "70px", base: "50px" }}
          />
        </a>
      </Box>
    </Flex>
  );
}

export default Register;
