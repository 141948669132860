import { useNavigate } from "react-router";
import React, { useState } from "react";
import "./Login.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { storeToken } from "../Utilities/LocalStorage";
import { Link as RouterLink } from "react-router-dom";
import {
  Flex,
  Box,
  Image,
  Text,
  Center,
  Container,
  Input,
  InputGroup,
  Spinner,
  InputLeftAddon,
  Button,
} from "@chakra-ui/react";
import { i18n } from "../i18n/config";

function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [login, setLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState(i18n.language);
  const [userData, setUserData] = useState({
    name: "",
    phoneNumber: "",
    password: "",
    serviceType: "",
    gender: "",
    farmAddress: "",
    coordinates: "",
    serviceProviderOptions: [],
    Terms: "",
  });

  const [loginStatus, setLoginStatus] = useState(false);
  const [show, setShow] = React.useState(false);
  const handleShowPassword = () => setShow(!show);

  const buttonStyles = {
    colorScheme: "yellow",
    size: "md",
    borderRadius: "md",
    _hover: {
      bg: "yellow.600",
    },
    transition: "ease-in-out",
    transitionDuration: "150ms",
  };

  // Function to login with api and save response in localStorage
  const handleLogin = async () => {
    const phoneNumber = userData.phoneNumber.trim();
    const password = userData.password.trim();

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}/v1/auth/login`,
        {
          phoneNumber,
          password,
        },
      );

      if (response.data) {
        storeToken(response.data.tokens.access);
        if (response.data.user.serviceType === "bookingAgent") {
          navigate("/createBooking");
        } else if (response.data.user.serviceType === "farmer") {
          navigate("/farmerBooking");
        } else {
          navigate("/viewBooking");
        }
      } else {
        setLoginStatus(false);
      }
    } catch (error) {
      setLoading(false);
      alert(t("wrong-cred"));
      console.log("Login error:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const passwordValue = userData.password.trim();

    // Login
    await handleLogin();
    if (loginStatus) {
      navigate("/createBooking");
    }
  };

  const handleChangeLanguage = () => {
    if (language === "km") {
      i18n.changeLanguage("en");
      setLanguage("en");
    } else {
      i18n.changeLanguage("km");
      setLanguage("km");
    }
  };

  return (
    <>
      {loading && (
        <Flex
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          width="100vw"
          height="100vh"
          align="center"
          justify="center"
          backgroundColor="rgba(255, 255, 255, 0.5)" // Semi-transparent white
          zIndex="modal" // Ensures it's above everything else
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Flex>
      )}

      <Flex direction={{ base: "column", md: "row" }} w="100vw" h="100vh">
        <Center w={{ base: "full", md: "50%" }} h={"full"}>
          <Container h={"full"}>
            <Flex direction={"row"} justify={"space-between"}>
              <RouterLink to="/">
                <img
                  src="/FarmHero__Logomark_Gold.png"
                  width="90"
                  height="60"
                  alt=""
                />
              </RouterLink>
              <Box zIndex="tooltip">
                <Image
                  src={language === "km" ? "/cambodia.png" : "/uk.png"}
                  alt="Descriptive Alt Text"
                  w="50px"
                  h="30px"
                  onClick={handleChangeLanguage}
                />
              </Box>
            </Flex>
            <Box w={{ base: "full", md: "lg" }} p="8" boxShadow="xl" bg="white">
              <Text fontWeight={"bold"}>{t("login")}</Text>

              {/* <h1>{login ? 'Login' : 'Register'}</h1> */}
              <form onSubmit={handleSubmit}>
                <label htmlFor="phoneNumber">{t("phone-number")}</label>
                <br></br>

                <InputGroup>
                  <InputLeftAddon>+855</InputLeftAddon>
                  <Input
                    type="tel"
                    placeholder={t("enter-phone-number")}
                    required
                    onChange={(e) =>
                      setUserData((prevState) => ({
                        ...prevState,
                        phoneNumber: e.target.value,
                      }))
                    }
                  />
                </InputGroup>
                <br></br>
                {/* <label htmlFor="Password">Password</label> */}
                <label htmlFor="Password">{t("password")}</label>
                <br></br>

                <Input
                  pr="3rem"
                  type={show ? "text" : "password"}
                  placeholder={t("enter-password")}
                  value={userData.password}
                  required
                  onChange={(e) =>
                    setUserData((prevState) => ({
                      ...prevState,
                      password: e.target.value,
                    }))
                  }
                  height="2.5rem" // Set a fixed height for the input field
                />

                {/* Set a fixed height for the InputRightElement */}
                <Button
                  {...buttonStyles}
                  h="2.5rem"
                  w="30%"
                  size="sm"
                  onClick={handleShowPassword}
                >
                  {show ? `${t("hide")}` : `${t("show")}`}
                </Button>

                <p className="password-instruction">
                  {/* Password must be at least 8 characters long. */}
                  {t("passwordValid")}
                </p>
                <Button {...buttonStyles} onClick={handleSubmit}>
                  {" "}
                  {t("login")}{" "}
                </Button>
              </form>

              <div className="bottom-section bottomSectionContainer">
                <p>{t("newUser")}</p>
                <a className="Apple" href="/register">
                  {t("register")}
                </a>
              </div>
              <div className="bottom-section bottomSectionContainer">
                <p>{t("forget-password")}</p>
                <a className="Apple" href="/resetPassword">
                  {t("click-here")}
                </a>
              </div>
            </Box>
          </Container>
        </Center>
        <Box
          w={{ base: "full", md: "50%" }}
          display={{ base: "none", md: "block" }}
        >
          <Image src="../randy.jpg" w={"full"} h={"full"} objectFit={"cover"} />
        </Box>
        <Box position="fixed" bottom="5" right="5" zIndex="tooltip">
          <a href="https://t.me/+855975296777">
            <Image
              src="/telegram.png"
              alt="Descriptive Alt Text"
              w={{ md: "70px", base: "50px" }}
              h={{ md: "70px", base: "50px" }}
            />
          </a>
        </Box>
      </Flex>
    </>
  );
}

export default Login;
