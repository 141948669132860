import React, { useEffect, useState } from "react";
import { getToken } from "../Utilities/LocalStorage";
import { decodeToken } from "../Utilities/JsonDecode";
import UserService from "./Services/UserService";
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Divider,
  Flex,
  Button,
  Link,
} from "@chakra-ui/react";
import BookingService from "./Services/BookingService";
import { useTranslation } from "react-i18next";

const UserDetails = ({ booking, serviceType }) => {
  const { t } = useTranslation();
  const [userDetails, setUserDetails] = useState(null);
  const [booked, setBooked] = useState(false);
  const props = { ...booking };
  const [currentUser, setCurrentUser] = useState(null);

  const handleServiceCompleted = async () => {
    try {
      await BookingService.patch(`/${booking.id}/serviceCompleted`, {
        acceptanceID: props.acceptanceID,
        serviceCompleted: true,
      });
      console.log("Service complete");
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  // const getUserRole = async () => {
  //   const token = getToken();
  //   const serviceType = decodeTokenObject(token)
  //   setServiceType(serviceType);
  // };

  const handlePayment = async () => {
    try {
      await BookingService.patch(`/${booking.id}/paymentConfirmed`, {
        acceptanceID: props.acceptanceID,
        paymentConfirmed: true,
      });
      console.log("Payment Completed");
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  const handleCancel = async () => {
    console.log("handleCancel");
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = getToken();
      const id = decodeToken(token);
      try {
        const user = await UserService.get(`/${id}`);
        setCurrentUser(user.data);
        if (user) {
          const userServiceType = user.data.serviceType;
          if (props.preferredSP?.id === "") {
            setBooked(false);
          } else {
            let detailsId =
              userServiceType === "farmer" ||
              userServiceType === "bookingAgent" ||
              userServiceType === "serviceProvider"
                ? props.preferredSP?.id
                : props.customerId;
            const fetchedUserDetails = await UserService.get(`/${detailsId}`);
            setUserDetails(fetchedUserDetails.data);
            setBooked(true);
          }
        }
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      }
    };

    fetchUserDetails();
    // getUserRole();
  }, [booking]); // You might need to adjust this dependency array based on your needs

  return (
    <>
      {/* first conditional rendering */}
      {props.bookingStatus === "unconfirmed" &&
      serviceType === "serviceProvider" ? (
        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          p={6}
          m={6}
          w={{ sm: "full" }}
        >
          <VStack align="stretch" spacing={4}>
            <Heading as="h3" size="lg">
              {serviceType === "farmer" ? t("sp-detail") : t("farmer-detail")}
            </Heading>
            <Divider />
            <Text>{t("no-acceptance")}</Text>
          </VStack>
        </Box>
      ) : (
        <Flex direction={"column"} w="full">
          <Box
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            p={6}
            m={6}
          >
            <VStack align="stretch" spacing={4}>
              <Heading as="h3" size="lg">
                {serviceType === "farmer" ? t("sp-detail") : t("farmer-detail")}
              </Heading>
              <Divider />
              {/* second conditional rendering */}
              <HStack justify="space-between">
                <Text fontWeight="bold">{t("full-name")}:</Text>
                <Text>{booking.customerDetails.name}</Text>
              </HStack>
              <HStack justify="space-between">
                <Text fontWeight="bold">{t("phone-number")}:</Text>
                <Text>{booking.customerDetails.phoneNumber}</Text>
              </HStack>
              <HStack justify="space-between">
                <Text fontWeight="bold">{t("gender")}:</Text>
                <Text>{t(`${booking.customerDetails.gender}`)}</Text>
              </HStack>
              <HStack justify="space-between">
                <Text fontWeight="bold">{t("address")}:</Text>
                <Link
                  isExternal
                  href={`http://maps.google.com?q=${booking.coordinates.lat},${booking.coordinates.lng}`}
                >
                  {booking.farmAddress}
                </Link>
              </HStack>
              {/* equipments */}
              <HStack justify="space-between">
                <Text fontWeight="bold">{t("equipment-type")}:</Text>
                <Text>{t(`${props.serviceType}`)}</Text>
              </HStack>
            </VStack>
          </Box>
        </Flex>
      )}
    </>
  );
};

export default UserDetails;
