import React from "react";
import { useNavigate } from "react-router-dom";
import "./BookNow.css";
import { useTranslation } from "react-i18next";

function BookNow() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function openPage() {
    navigate("/createBooking");
  }
  function openPage1() {
    navigate("/Login");
  }

  return (
    <div className="button-container-BookNow">
      <button type="button" onClick={openPage}>
        {t("make-booking")}
        {/* Book Now*/}
      </button>
      <button type="button" onClick={openPage1}>
        {t("register-sp")}
        {/*Become a Service Provider*/}
      </button>
    </div>
  );
}

export default BookNow;
