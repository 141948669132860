import React from "react";
import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Tag,
  TagLabel,
  TagCloseButton,
  Wrap,
  WrapItem,
  VStack,
  HStack,
  Input,
  useNumberInput,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

// Item subcomponent
const Item = ({ item, onUpdate, t }) => {
  const handleQuantityChange = (valueString) => {
    onUpdate(parseFloat(valueString), item); // Assuming decimal handling is necessary
  };

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: item.quantity,
      min: 1,
      max: 6,
      precision: 0,
      onChange: handleQuantityChange,
    });

  const incProps = getIncrementButtonProps();
  const decProps = getDecrementButtonProps();
  const inputProps = getInputProps({ value: item.quantity.toString() }); // Ensure value is a string and reflects the current item's quantity

  return (
    <WrapItem flexDirection={"column"}>
      <Tag size="lg" borderRadius="full" variant="solid" bg="brand.blue" mr={2}>
        <TagLabel color="white">{t(item.type)}</TagLabel>
        <TagCloseButton onClick={() => onUpdate(0, item)} />
      </Tag>
      <HStack alignItems={"center"} margin={"auto"}>
        <Button {...decProps} colorScheme="red">
          -
        </Button>
        <Input {...inputProps} />{" "}
        {/* The input now uses the current quantity for display */}
        <Button {...incProps} colorScheme={"green"}>
          +
        </Button>
      </HStack>
    </WrapItem>
  );
};

const DropdownSelectWithTags = ({ selectedItemsProp, onSelectionChange }) => {
  const { t } = useTranslation();
  // Example items to select from
  const items = [
    { type: "LAND_LEVELER", quantity: 1 },
    { type: "RICE_NO_TILL_PLANTER", quantity: 1 },
    { type: "MAIZE_NO_TILL_PLANTER", quantity: 1 },
    { type: "SOIL_RIDGING_EQUIPMENT", quantity: 1 },
    { type: "CASSAVA_ROOT_HARVESTER", quantity: 1 },
    { type: "DISC_PLOUGH_3", quantity: 1 },
    { type: "DISC_PLOUGH_6", quantity: 1 },
    { type: "ROTAVATOR_DRIED", quantity: 1 },
    { type: "ROTAVATOR_WET", quantity: 1 },
    { type: "LASER_LAND_LEVELER", quantity: 1 },
    { type: "DRONE", quantity: 1 },
  ];

  const handleSelectItem = (item) => {
    if (!selectedItemsProp.some((i) => i.type === item.type)) {
      const newSelectedItems = [...selectedItemsProp, item];
      onSelectionChange(newSelectedItems);
    }
  };

  const handleUpdate = (newQuantity, item) => {
    if (newQuantity === 0) {
      // Removing the item
      const newSelectedItems = selectedItemsProp.filter(
        (i) => i.type !== item.type,
      );
      onSelectionChange(newSelectedItems);
    } else {
      // Updating the quantity
      const newSelectedItems = selectedItemsProp.map((i) => {
        if (i.type === item.type) {
          return { ...i, quantity: newQuantity };
        }
        return i;
      });
      onSelectionChange(newSelectedItems);
    }
  };

  return (
    <Box width="100%">
      <VStack justify="space-between">
        <Menu>
          <MenuButton
            as={Button}
            bg="brand.yellow"
            w={"200px"}
            rightIcon={<ChevronDownIcon />}
          >
            {t("equipments")}
          </MenuButton>
          <MenuList>
            {items.map((item) => (
              <MenuItem key={item.type} onClick={() => handleSelectItem(item)}>
                {t(item.type)}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        <Wrap>
          {selectedItemsProp.map((item) => (
            <Item key={item.type} item={item} onUpdate={handleUpdate} t={t} />
          ))}
        </Wrap>
      </VStack>
    </Box>
  );
};

export default DropdownSelectWithTags;
