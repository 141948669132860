import MainLayout from "../Layout/MainLayout";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import React, { useState, useEffect } from "react";
import "./Login.css";
import GetAddress from "../CustomFunctions/geoCode";
import GoogleMaps from "./GoogleMap";
import {
  Flex,
  Center,
  VStack,
  Button,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Select,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import DropdownSelectWithTags from "../Components/DropdownSelectWithTags";
import UserService from "../Components/Services/UserService";
import { getToken, isTokenExpired } from "../Utilities/LocalStorage";
import { decodeToken } from "../Utilities/JsonDecode";
import { updateCachedUserData } from "../Components/Services/CustomFunctions/cacheUser";
import { useTranslation } from "react-i18next";
import { i18n } from "../i18n/config";
import RegisterGoogleMap from "./RegisterGoogleMap";

function EditProfile() {
  // const history = useNavigate();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [id, setId] = useState(null);
  const [serviceType, setServiceType] = useState(null);
  const [image, setImage] = useState("");
  const [formData, setFormData] = useState({
    farmAddress: "",
    coordinates: {
      lat: 0,
      lng: 0,
    },
    serviceType: "",
    serviceProviderOptions: [],
    gender: "",
    dob: "",
    phoneNumber: "",
    name: "",
  });
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      farmAddress: user?.farmAddress,
      coordinates: user?.coordinates,
      serviceType: user?.serviceType,
      serviceProviderOptions: user?.serviceProviderOptions,
      dob: user?.dob,
      gender: user?.gender,
      phoneNumber: user?.phoneNumber,
      name: user?.name,
    },
  });
  const [userLocation, setUserLocation] = useState({
    lat: 11.5642373,
    lng: 104.9111526,
  });

  const buttonStyles = {
    colorScheme: "yellow",
    size: "md",
    borderRadius: "md",
    _hover: {
      bg: "yellow.600",
    },
    transition: "ease-in-out",
    transitionDuration: "150ms",
  };

  async function onSubmit(values) {
    const updateData = {
      ...user,
      ...values,
    };
    updateData.serviceProviderOptions = selectedItems;
    updateData["serviceProviderOptions"] = selectedItems;
    // updateData.profileImg = image;
    try {
      await UserService.patch(`/${id}`, updateData);
      await updateCachedUserData(id);
      navigate(`/userProfile/${id}`);
    } catch (e) {
      console.error(e);
    }
  }

  const getUserData = async () => {
    const token = getToken();
    const uid = decodeToken(token);
    setId(uid);
    try {
      const userData = await UserService.get(`${uid}`);
      if (userData) {
        setUser(userData.data);
        setSelectedItems(userData.data.serviceProviderOptions);
        Object.keys(userData.data).forEach((key) => {
          setValue(key, userData.data[key]);
        });
        setUserLocation({
          lat: userData.data.coordinates.lat,
          lng: userData.data.coordinates.lng,
        });
        setServiceType(userData.data.serviceType);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const setLocation = async (location) => {
    setUserLocation({ lat: location.lat, lng: location.lng });
    const address = await GetAddress(location.lat, location.lng);
    setValue("farmAddress", address);
    setValue("coordinates", { lat: location.lat, lng: location.lng });
  };

  // const convertToBase64 = (file, no_metadata = false) => {
  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsDataURL(file);
  //     fileReader.onload = () => {
  //       let encoded = fileReader.result.toString();
  //       if (no_metadata) {
  //         encoded = encoded.replace("data:", "").replace(/^.+,/, "");
  //       }
  //       resolve(encoded);
  //     };

  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // };

  // const handleFileChange = async (event) => {
  //   const file = event.target.files[0];
  //   if (!file) {
  //     return;
  //   }
  //   const base64 = await convertToBase64(file);
  //   setImage(base64);
  //   // Here you can also send the base64 string to your backend if you want to upload immediately
  //   // uploadImage(base64);
  // };

  const checkToken = async () => {
    const expired = isTokenExpired();
    if (expired === true) return navigate("/login");
    getUserData();
  };

  const handleMarkerPositionChange = async (newPosition) => {
    setValue("coordinates", { lat: newPosition.lat, lng: newPosition.lng });
    setFormData({
      ...formData,
      ["coordinates"]: { lat: newPosition.lat, lng: newPosition.lng },
    });
    const address = await GetAddress(newPosition.lat, newPosition.lng);
    setValue("farmAddress", address);
    setFormData({
      ...formData,
      ["farmAddress"]: address,
    });
  };

  useEffect(() => {
    checkToken();
  }, []);
  return (
    <MainLayout>
      {user && (
        <>
          {/* <Flex direction={"column"}>
            <Avatar
              size="2xl"
              src={
                user?.profileImg === ""
                  ? "https://bit.ly/dan-abramov"
                  : user?.profileImg
              }
            />
            <Input
              type="file"
              size="md"
              width={"20%"}
              variant="unstyled"
              accept="image/*"
              onChange={handleFileChange}
            />
          </Flex> */}
          <Button
            {...buttonStyles}
            size="lg"
            w={{ md: "20%", sm: "100%" }}
            ml={{ md: 5, sm: 0 }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon boxSize={8} />
          </Button>
          <Center w={{ base: "full", md: "50%" }} margin={"auto"}>
            <Flex
              direction={{ base: "column", md: "row" }}
              gap={5}
              w="full"
              justify={"space-evenly"}
              m={5}
            >
              <VStack w={"full"}>
                <FormControl isInvalid={errors.farmAddress}>
                  <FormLabel htmlFor="farmAddress">{t("address")}</FormLabel>
                  <Input
                    id="farmAddress"
                    placeholder={t("address")}
                    {...register("farmAddress", {
                      required: "This is required",
                    })}
                  />
                  <FormErrorMessage>
                    {errors.farmAddress && errors.farmAddress.message}
                  </FormErrorMessage>
                </FormControl>

                <RegisterGoogleMap
                  center={userLocation}
                  setUserLocation={handleMarkerPositionChange}
                  onUserLocationChange={setLocation}
                />
                <br></br>
                <br></br>
                <FormControl>
                  <FormLabel>{t("gender")}</FormLabel>
                  <Select
                    id="gender"
                    {...register("gender", {
                      required: "This is required",
                    })}
                  >
                    <option value={"male"}>{t("male")}</option>
                    <option value={"female"}>{t("female")}</option>
                    <option value={"other"}>{t("noShow")}</option>
                    <option value={"other"}>{t("other")}</option>
                  </Select>
                </FormControl>
              </VStack>

              <VStack w={"full"}>
                <FormControl isInvalid={errors.name}>
                  <FormLabel htmlFor="name">{t("full-name")}</FormLabel>
                  <Input
                    id="name"
                    placeholder={t("full-name")}
                    {...register("name", {
                      required: "This is required",
                    })}
                  />
                  <FormErrorMessage>
                    {errors.name && errors.name.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.dob}>
                  <FormLabel htmlFor="dob">{t("dob")}</FormLabel>
                  <Input
                    id="dob"
                    type="date"
                    {...register("dob", {
                      required: "This is required",
                      validate: {
                        is18OrOlder: (value) => {
                          const today = new Date();
                          const birthDate = new Date(value);
                          const cutoffDate = new Date(
                            today.getFullYear() - 18,
                            today.getMonth(),
                            today.getDate(),
                          );

                          // Check if the birthDate is before the cutoffDate
                          return birthDate <= cutoffDate || i18n.t("least-18");
                        },
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.dob && errors.dob.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.phoneNumber}>
                  <FormLabel htmlFor="phoneNumber">
                    {t("phone-number")}
                  </FormLabel>
                  <Input
                    id="phoneNumber"
                    placeholder={t("phone-number")}
                    {...register("phoneNumber", {
                      required: "This is required",
                    })}
                  />
                  <FormErrorMessage>
                    {errors.phoneNumber && errors.phoneNumber.message}
                  </FormErrorMessage>
                </FormControl>

                {serviceType && serviceType === "serviceProvider" && (
                  <DropdownSelectWithTags
                    selectedItemsProp={selectedItems}
                    onSelectionChange={setSelectedItems}
                  />
                )}
                <Button {...buttonStyles} onClick={handleSubmit(onSubmit)}>
                  {t("update-profile")}
                </Button>
              </VStack>
            </Flex>
          </Center>
        </>
      )}
    </MainLayout>
  );
}

export default EditProfile;
